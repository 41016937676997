import type { Theme } from '@material-ui/core/styles/createTheme';
import { TypographyProps } from './Typo.interface';
import { makeStyles } from '@material-ui/core/styles';

// HELPERS
import { getColorByColorCode } from 'helpers';

const commonStateStyle = () => ({
  color: (props: TypographyProps) => {
    return getColorByColorCode(props.color);
  },
  fontWeight: (props: TypographyProps) => {
    if (props.bold) {
      return 700;
    }

    if (props.fontWeight === 'bold') {
      return 700;
    }

    if (props.fontWeight === 'regular') {
      return 400;
    }

    if (props.fontWeight === 'semibold') {
      return 600;
    }
  }
});

export const useClassesTypo = makeStyles<Theme, TypographyProps, string>(() => ({
  nh_customTypoWrapper: {
    ...commonStateStyle()
  },
  nh_customTypoWithClickWrapper: {
    ...commonStateStyle(),
    '&:hover': {
      color: (props: TypographyProps) => {
        if (props.hoverColor) {
          return getColorByColorCode(props.hoverColor);
        }
      }
    }
  }
}));
