import React, { FC } from 'react';
import MuiDialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogContentText from '@material-ui/core/DialogContentText';
import MuiDialogTitle from '@material-ui/core/DialogTitle';

// INTERFACE
import {
  DialogProps,
  DialogActionsProps,
  DialogContentProps,
  DialogContentTextProps,
  DialogTitleProps
} from './Dialog.interface';

export const Dialog: FC<DialogProps> = ({ testId, ...props }) => {
  return <MuiDialog data-testid={testId} {...props} />;
};

export const DialogActions: FC<DialogActionsProps> = ({ testId, ...props }) => {
  return <MuiDialogActions data-testid={testId} {...props} />;
};

export const DialogContent: FC<DialogContentProps> = ({ testId, ...props }) => {
  return <MuiDialogContent data-testid={testId} {...props} />;
};

export const DialogContentText: FC<DialogContentTextProps> = ({ testId, ...props }) => {
  return <MuiDialogContentText data-testid={testId} {...props} />;
};

export const DialogTitle: FC<DialogTitleProps> = ({ testId, ...props }) => {
  return <MuiDialogTitle data-testid={testId} {...props} />;
};
