import React, { FC } from 'react';
import MuiRadio from '@material-ui/core/Radio';
import clsx from 'clsx';

// INTERFACE
import { RadioProps } from './Radio.interface';

// STYLE
import useClasses from './Radio.style';

// COMPONENT
import { Div } from 'components/Div';

const Radio: FC<RadioProps> = ({ label, testId, ...props }) => {
  const classes = useClasses({ ...props });

  if (label) {
    return (
      <Div display="flex" flexDirection="row" alignItems="center">
        <MuiRadio
          data-testid={testId}
          className={classes.nh_customRadioBoxRoot}
          disableRipple
          color="default"
          checkedIcon={<span className={clsx(classes.nh_customRadioBoxIcon, classes.nh_customRadioBoxCheckedIcon)} />}
          icon={<span className={classes.nh_customRadioBoxIcon} />}
          {...props}
        />
        {label}
      </Div>
    );
  }

  return (
    <MuiRadio
      data-testid={testId}
      className={classes.nh_customRadioBoxRoot}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.nh_customRadioBoxIcon, classes.nh_customRadioBoxCheckedIcon)} />}
      icon={<span className={classes.nh_customRadioBoxIcon} />}
      {...props}
    />
  );
};

export default Radio;
