import type { Theme } from '@material-ui/core/styles/createTheme';
import { ButtonStackProps } from './ButtonStack.interface';
import { makeStyles } from '@material-ui/core/styles';

const useClasses = makeStyles<Theme, ButtonStackProps, string>(({ ntucHealth }) => ({
  nh_customButtonStackWrapper: {
    '& .title': {
      color: ntucHealth.charcoal700
    },

    '& .MuiButton-label': {
      display: 'flex',
      flexDirection: 'column'
    },

    '& .selectedSlot': {
      background: ntucHealth.green600,
      boxShadow: 'none',
      color: ntucHealth.white
    },

    '& .slot': {
      border: `1px solid ${ntucHealth.charcoal700}`,
      color: ntucHealth.charcoal700
    }
  }
}));

export default useClasses;
