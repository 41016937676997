import React, { FC, useEffect, useState, useMemo, useCallback } from 'react';
import moment, { Moment } from 'moment';
import { DayPicker } from 'react-dates';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

// ELEMENT
import { Div } from 'components/Div';

// INTERFACE
import { DatePickerStaticProps } from './DatePicker.interface';

// STYLE
import { useClassesStaticMode } from './DatePicker.styles';

export const DatePickerStatic: FC<DatePickerStaticProps> = ({
  value,
  onChange,
  disabledDates,
  availabledDates,
  disabledPast,
  onMonthChange
}) => {
  const classes = useClassesStaticMode({});
  const [momentDate, setMomentDate] = useState<Moment | null | undefined>(null);
  const [calendarStartDate, setCalendarStartDate] = useState<Moment>(moment());

  useEffect(() => {
    if (availabledDates && availabledDates.length > 0 && disabledDates && disabledDates.length > 0) {
      throw new Error(
        'AvailabledDates and DisabledDates can not be available at the same time, One of them should be undefined'
      );
    }
  }, []);

  useEffect(() => {
    if (!value) {
      setMomentDate(null);
    }

    if (value) {
      setMomentDate(moment(value));
    }
  }, [value]);

  const handleChangeDate = (day: Moment) => {
    setMomentDate(day);

    if (onChange) {
      onChange(day);
    }
  };

  const renderCalendarDay = (day?: Moment | null) => {
    if (day && disabledDates && disabledDates.length > 0) {
      const isDisabled = disabledDates.find((v) => moment(v).isSame(day, 'days')) ? true : false;

      return (
        <td className="CalendarDay CalendarDay_1 CalendarDay__default CalendarDay__default_2" role="button">
          <div
            className={isDisabled ? 'nh_customDayContent disabled' : 'nh_customDayContent'}
            onClick={() => !isDisabled && handleChangeDate(day)}
            style={momentDate?.isSame(day, 'days') ? { backgroundColor: 'rgb(102, 179, 51)', color: '#fff' } : {}}
          >
            {day.format('D')}
          </div>
        </td>
      );
    }

    if (day && availabledDates && availabledDates.length > 0) {
      const isAvailable = availabledDates.find((v) => moment(v).isSame(day, 'days')) ? true : false;

      return (
        <td className="CalendarDay CalendarDay_1 CalendarDay__default CalendarDay__default_2" role="button">
          <div
            className={isAvailable ? 'nh_customDayContent' : 'nh_customDayContent disabled'}
            onClick={() => isAvailable && handleChangeDate(day)}
            style={momentDate?.isSame(day, 'days') ? { backgroundColor: 'rgb(102, 179, 51)', color: '#fff' } : {}}
          >
            {day.format('D')}
          </div>
        </td>
      );
    }

    if (day && availabledDates && availabledDates.length === 0) {
      return (
        <td className="CalendarDay CalendarDay_1 CalendarDay__default CalendarDay__default_2" role="button">
          <div
            className="nh_customDayContent disabled"
            style={momentDate?.isSame(day, 'days') ? { backgroundColor: 'rgb(102, 179, 51)', color: '#fff' } : {}}
          >
            {day.format('D')}
          </div>
        </td>
      );
    }

    if (day && (!availabledDates || !disabledDates || disabledDates.length === 0)) {
      return (
        <td className="CalendarDay CalendarDay_1 CalendarDay__default CalendarDay__default_2" role="button">
          <div
            className="nh_customDayContent"
            onClick={() => handleChangeDate(day)}
            style={momentDate?.isSame(day, 'days') ? { backgroundColor: 'rgb(102, 179, 51)', color: '#fff' } : {}}
          >
            {day.format('D')}
          </div>
        </td>
      );
    }

    return <td />;
  };

  const checkRenderNavPrvButton = useMemo(() => {
    const currentCalendatStartDate = calendarStartDate.get('month');
    const currentMonth = moment().get('month');

    if (disabledPast && currentCalendatStartDate - currentMonth === 0) return 'none';

    return 'block';
  }, [calendarStartDate]);

  const handleChangeMonth = useCallback(
    (v: Moment) => {
      setCalendarStartDate(v);

      const currentStartMonth = moment(v);
      const nextMonth = moment(v).add(1, 'month');

      if (onMonthChange) {
        onMonthChange(currentStartMonth, nextMonth);
      }
    },
    [onMonthChange]
  );

  const windownWidth = window.innerWidth;

  if (windownWidth <= 320) {
    return (
      <Div
        display="inline-flex"
        borderColor={{ all: 'grey' }}
        borderRadius={{ all: 5 }}
        borderWidth={{ all: 2 }}
        width="100%"
        justifyContent="center"
        flexDirection="row"
        className={classes.nh_customDateStatic}
      >
        <DayPicker
          isFocused
          noBorder
          numberOfMonths={1}
          navPrev={
            <div style={{ position: 'absolute', top: 27, left: 22, display: checkRenderNavPrvButton }}>
              <ArrowBackIosIcon />
            </div>
          }
          navNext={
            <div style={{ position: 'absolute', right: 22, top: 27 }}>
              <ArrowForwardIosIcon />
            </div>
          }
          onPrevMonthClick={handleChangeMonth}
          onNextMonthClick={handleChangeMonth}
          daySize={35}
          renderCalendarDay={(day) => renderCalendarDay(day.day)}
          hideKeyboardShortcutsPanel
        />
      </Div>
    );
  }

  if (windownWidth <= 375 && windownWidth > 320) {
    return (
      <Div
        display="inline-flex"
        borderColor={{ all: 'grey' }}
        borderRadius={{ all: 5 }}
        borderWidth={{ all: 2 }}
        width="100%"
        justifyContent="center"
        flexDirection="row"
        className={classes.nh_customDateStatic}
      >
        <DayPicker
          isFocused
          noBorder
          numberOfMonths={1}
          navPrev={
            <div style={{ position: 'absolute', top: 27, left: 22, display: checkRenderNavPrvButton }}>
              <ArrowBackIosIcon />
            </div>
          }
          navNext={
            <div style={{ position: 'absolute', right: 22, top: 27 }}>
              <ArrowForwardIosIcon />
            </div>
          }
          onPrevMonthClick={handleChangeMonth}
          onNextMonthClick={handleChangeMonth}
          daySize={40}
          renderCalendarDay={(day) => renderCalendarDay(day.day)}
          hideKeyboardShortcutsPanel
        />
      </Div>
    );
  }

  if (windownWidth <= 500 && windownWidth > 375) {
    return (
      <Div
        display="inline-flex"
        borderColor={{ all: 'grey' }}
        borderRadius={{ all: 5 }}
        borderWidth={{ all: 2 }}
        width="100%"
        justifyContent="center"
        flexDirection="row"
        className={classes.nh_customDateStatic}
      >
        <DayPicker
          isFocused
          noBorder
          numberOfMonths={1}
          navPrev={
            <div style={{ position: 'absolute', top: 27, left: 30, display: checkRenderNavPrvButton }}>
              <ArrowBackIosIcon />
            </div>
          }
          navNext={
            <div style={{ position: 'absolute', right: 30, top: 27 }}>
              <ArrowForwardIosIcon />
            </div>
          }
          onPrevMonthClick={handleChangeMonth}
          onNextMonthClick={handleChangeMonth}
          daySize={50}
          renderCalendarDay={(day) => renderCalendarDay(day.day)}
          hideKeyboardShortcutsPanel
        />
      </Div>
    );
  }

  if (windownWidth <= 600 && windownWidth > 500) {
    return (
      <Div
        display="inline-flex"
        borderColor={{ all: 'grey' }}
        borderRadius={{ all: 5 }}
        borderWidth={{ all: 2 }}
        width="100%"
        justifyContent="center"
        flexDirection="row"
        className={classes.nh_customDateStatic}
      >
        <DayPicker
          isFocused
          noBorder
          numberOfMonths={2}
          // horizontalMonthPadding={30}
          navPrev={
            <div style={{ position: 'absolute', top: 27, left: 30, display: checkRenderNavPrvButton }}>
              <ArrowBackIosIcon />
            </div>
          }
          navNext={
            <div style={{ position: 'absolute', right: 30, top: 27 }}>
              <ArrowForwardIosIcon />
            </div>
          }
          onPrevMonthClick={handleChangeMonth}
          onNextMonthClick={handleChangeMonth}
          daySize={35}
          renderCalendarDay={(day) => renderCalendarDay(day.day)}
          hideKeyboardShortcutsPanel
        />
      </Div>
    );
  }

  if (windownWidth <= 768 && windownWidth > 600) {
    return (
      <Div
        display="inline-flex"
        borderColor={{ all: 'grey' }}
        borderRadius={{ all: 5 }}
        borderWidth={{ all: 2 }}
        width="100%"
        justifyContent="center"
        flexDirection="row"
        className={classes.nh_customDateStatic}
      >
        <DayPicker
          isFocused
          noBorder
          numberOfMonths={2}
          horizontalMonthPadding={30}
          navPrev={
            <div style={{ position: 'absolute', top: 27, left: 42, display: checkRenderNavPrvButton }}>
              <ArrowBackIosIcon />
            </div>
          }
          navNext={
            <div style={{ position: 'absolute', right: 42, top: 27 }}>
              <ArrowForwardIosIcon />
            </div>
          }
          onPrevMonthClick={handleChangeMonth}
          onNextMonthClick={handleChangeMonth}
          daySize={38}
          renderCalendarDay={(day) => renderCalendarDay(day.day)}
          hideKeyboardShortcutsPanel
        />
      </Div>
    );
  }

  return (
    <Div
      display="inline-flex"
      borderColor={{ all: 'grey' }}
      borderRadius={{ all: 5 }}
      borderWidth={{ all: 2 }}
      width="100%"
      justifyContent="center"
      flexDirection="row"
      className={classes.nh_customDateStatic}
    >
      <DayPicker
        isFocused
        noBorder
        numberOfMonths={2}
        horizontalMonthPadding={50}
        navPrev={
          <div style={{ position: 'absolute', top: 27, left: 60, display: checkRenderNavPrvButton }}>
            <ArrowBackIosIcon />
          </div>
        }
        navNext={
          <div style={{ position: 'absolute', right: 60, top: 27 }}>
            <ArrowForwardIosIcon />
          </div>
        }
        onPrevMonthClick={handleChangeMonth}
        onNextMonthClick={handleChangeMonth}
        daySize={40}
        renderCalendarDay={(day) => renderCalendarDay(day.day)}
        hideKeyboardShortcutsPanel
      />
    </Div>
  );
};
