import React, { FC } from 'react';
import { TypographyProps } from './Typo.interface';
import { Typography as MuiTypography, Link as MuiLink } from '@material-ui/core';
import { useClassesTypo } from './Typo.styles';

const TypographyWithClick: FC<
  Pick<TypographyProps, 'color' | 'onClick' | 'hoverColor' | 'variant' | 'bold' | 'children' | 'fontWeight'>
> = ({ color, onClick, hoverColor, variant, bold, children, fontWeight }) => {
  const baseClasses = useClassesTypo({
    color,
    bold,
    onClick,
    hoverColor,
    fontWeight
  });

  return (
    <MuiLink
      className={baseClasses.nh_customTypoWithClickWrapper}
      component="button"
      variant={variant}
      onClick={onClick}
    >
      {children}
    </MuiLink>
  );
};

const Typography: FC<TypographyProps> = ({ color, bold, onClick, hoverColor, children, fontWeight, ...rest }) => {
  const baseClasses = useClassesTypo({
    color,
    bold,
    onClick,
    hoverColor,
    fontWeight
  });

  return (
    <MuiTypography className={baseClasses.nh_customTypoWrapper} {...rest}>
      {children}
    </MuiTypography>
  );
};

const BaseTypography: FC<TypographyProps> = ({
  color,
  bold,
  onClick,
  hoverColor,
  children,
  variant,
  fontWeight,
  testId,
  ...rest
}) => {
  if (onClick) {
    return (
      <TypographyWithClick
        data-testid={testId}
        onClick={onClick}
        color={color}
        bold={bold}
        hoverColor={hoverColor}
        variant={variant}
        fontWeight={fontWeight}
      >
        {children}
      </TypographyWithClick>
    );
  }

  return (
    <Typography data-testid={testId} color={color} bold={bold} variant={variant} fontWeight={fontWeight} {...rest}>
      {children}
    </Typography>
  );
};
BaseTypography.defaultProps = {
  variant: 'body1',
  color: 'primary',
  hoverColor: 'secondary'
};

export default BaseTypography;
