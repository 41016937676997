import type { Theme } from '@material-ui/core/styles/createTheme';
import { ButtonProps } from './Button.interface';
import { makeStyles } from '@material-ui/core/styles';

const commonStateStyle = (ntucHealth: Theme['ntucHealth']) => ({
  height: (props: ButtonProps) => {
    if (props.fullHeight) {
      return '100%';
    }
  },
  borderRadius: 'unset',
  backgroundColor: (props: ButtonProps) => {
    if (props.isGreenBg && !props.disabled) {
      return ntucHealth.green600;
    }
  },
  color: (props: ButtonProps) => {
    if (props.isGreenBg) {
      return ntucHealth.white;
    }
  },
  padding: (props: ButtonProps) => {
    if (props.withoutPadding) {
      return 'unset';
    }
  },
  borderWidth: 1,
  '&:hover': {
    backgroundColor: (props: ButtonProps) => {
      if (props.isGreenBg) {
        return ntucHealth.green600;
      }
    },
    borderWidth: 1,
    borderColor: (props: ButtonProps) => {
      if (props.isGreenBg && props.variant === 'secondary') {
        return ntucHealth.green600;
      }

      if (props.isBlackBorder && props.variant === 'secondary') {
        return ntucHealth.charcoal900;
      }

      if (props.disabled && props.variant === 'secondary') {
        return ntucHealth.charcoal300;
      }

      return ntucHealth.teal700;
    }
  },
  borderColor: (props: ButtonProps) => {
    if (props.isGreenBg && props.variant === 'secondary') {
      return ntucHealth.green600;
    }

    if (props.isBlackBorder && props.variant === 'secondary') {
      return ntucHealth.charcoal900;
    }

    if (props.disabled && props.variant === 'secondary') {
      return ntucHealth.charcoal300;
    }

    return ntucHealth.teal700;
  }
});

const useClasses = makeStyles<Theme, ButtonProps, string>(({ ntucHealth }: Theme) => ({
  nh_customButton: {
    ...commonStateStyle(ntucHealth)
  },
  wrapper: {
    position: 'relative'
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}));

export default useClasses;
