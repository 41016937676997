import type { Theme } from '@material-ui/core/styles/createTheme';
import { ToastProps } from './Toast.interface';
import { makeStyles } from '@material-ui/core/styles';

const useClasses = makeStyles<Theme, Pick<ToastProps, 'variant'>, string>(({ ntucHealth }: Theme) => ({
  nh_customSackBar: {
    '&.MuiSnackbar-anchorOriginTopRight': {
      top: 100
    }
  },
  nh_customToast: {
    backgroundColor: (props: Pick<ToastProps, 'variant'>) => {
      if (props.variant === 'success') {
        return ntucHealth.green600;
      }

      if (props.variant === 'warning') {
        return ntucHealth.yellowOrange;
      }

      if (props.variant === 'info') {
        return ntucHealth.teal700;
      }

      if (props.variant === 'error') {
        return ntucHealth.red900;
      }

      if (props.variant === 'support') {
        return ntucHealth.limeGreen;
      }

      return ntucHealth.charcoal900;
    }
  }
}));

export default useClasses;
