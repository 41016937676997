import React, { FC, Fragment } from 'react';
import { Table, TableRow, TableHead, TableBody, TableCell, TableContainer } from '@material-ui/core';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

// INTERFACE
import { PackageDetailsTableProps } from './PackageDetailsTable.interface';

// COMPONENT
import { Div } from 'components/Div';
import { Typography } from 'components/Typography';

// STYLE
import useClasses from './PackageDetailsTable.styles';

const PackageDetailsTable: FC<PackageDetailsTableProps> = ({
  title,
  headers,
  items,
  Footer,
  onRemoveDiscount,
  testId
}) => {
  const classes = useClasses({ title, headers, items, Footer, onRemoveDiscount });

  return (
    <Div data-testid={testId} className={classes.nh_packeDeitalsTableWrapper}>
      <Typography variant="h3" className="title">
        {title}
      </Typography>
      <TableContainer>
        <Table className="nh-custom-package-table-container" aria-label="spanning table">
          <TableHead>
            <TableRow>
              {headers.map((row, index) => (
                <TableCell
                  key={`${row}-${index}`}
                  align={index === 0 ? 'left' : 'right'}
                  className={index === 0 ? classes.nhStickyColumn : ''}
                >
                  {row}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((row, index) => {
              return (
                <Fragment key={`${row.id}-${index}`}>
                  <TableRow>
                    <TableCell className={classes.nhStickyColumn}>{row.itemDescription}</TableCell>
                    {headers.includes('Quantity') && <TableCell align="right">{row.quantity}</TableCell>}
                    {headers.includes('Unit Price') && <TableCell align="right">${row.unitPrice.toFixed(2)}</TableCell>}
                    {headers.includes('Amount') && <TableCell align="right">${row.amount.toFixed(2)}</TableCell>}
                  </TableRow>

                  {row.discount && (
                    <TableRow className={classes.discountRow}>
                      <TableCell className={classes.nhStickyColumn}>
                        <Div display="flex" alignItems="center" flexDirection="row">
                          {onRemoveDiscount && (
                            <Div margin={{ right: 5, top: 5 }} onCLick={() => onRemoveDiscount(index)}>
                              <RemoveCircleIcon className={classes.nhRemoveIcon} fontSize="small" />
                            </Div>
                          )}
                          {row.discount.description}
                        </Div>
                      </TableCell>
                      {headers.includes('Quantity') && <TableCell />}
                      {headers.includes('Unit Price') && <TableCell />}
                      {headers.includes('Amount') && (
                        <TableCell align="right">-${row.discount.amount && row.discount.amount.toFixed(2)}</TableCell>
                      )}
                    </TableRow>
                  )}
                </Fragment>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Div>{Footer}</Div>
    </Div>
  );
};

export default PackageDetailsTable;
