import {
  Dialog as BaseDialog,
  DialogActions as BaseDialogActions,
  DialogContent as BaseDialogContent,
  DialogContentText as BaseDialogContentText,
  DialogTitle as BaseDialogTitle
} from './Dialog';

// EXPORT
export const Dialog = BaseDialog;
export const DialogActions = BaseDialogActions;
export const DialogContent = BaseDialogContent;
export const DialogContentText = BaseDialogContentText;
export const DialogTitle = BaseDialogTitle;

export type {
  DialogActionsProps,
  DialogContentProps,
  DialogTitleProps,
  DialogProps,
  DialogContentTextProps
} from './Dialog.interface';
