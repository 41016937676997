import type { Theme } from '@material-ui/core/styles/createTheme';
import { RadioGroupProps } from './RadioGroup.interface';
import { makeStyles } from '@material-ui/core/styles';

const useClasses = makeStyles<Theme, RadioGroupProps, string>(() => ({
  nh_customRadioGroup: {
    '&.MuiFormGroup-root': {
      display: 'unset'
    }
  }
}));

export default useClasses;
