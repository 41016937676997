import { makeStyles, Theme } from '@material-ui/core';
import { PriceCardProps } from './PriceCard.interface';

const usePriceCardClasses = makeStyles<Theme, PriceCardProps, string>(({ ntucHealth }) => ({
  customPriceCard: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '16px',
    boxShadow: '0 2px 6px 0 rgba(0, 0, 0, 0.16)',
    backgroundColor: ntucHealth.yellowWhite,
    textAlign: 'center',
    padding: '20px 10px',
    overflow: 'visible'
  },
  customPriceCardHeader: {
    color: ntucHealth.teal700,
    fontSize: '50px',
    fontWeight: 900,
    marginTop: 50
  },
  divider: {
    backgroundColor: '#707070'
  },
  ribbonWrapper: {
    width: '100%',
    filter: 'drop-shadow(-1px 6px 3px rgba(50, 50, 0, 0.5))'
  },
  ribbon: {
    position: 'absolute',
    left: -20,
    top: 0,
    clipPath: 'polygon(0 0, 90% 0%, 100% 100%, 0% 100%)'
  },
  after: {
    backgroundColor: '#505050',
    clipPath: 'polygon(0 0, 100% 100%, 100% 0)',
    position: 'absolute',
    left: -20,
    top: 40
  },
  sideContent: {
    display: 'flex',
    flexDirection: 'row'
  },
  customDivider: {
    width: 1,
    height: '100%',
    backgroundColor: '#707070',
    margin: 'auto'
  }
}));

export { usePriceCardClasses };
