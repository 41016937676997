import type { Theme } from '@material-ui/core/styles/createTheme';
import type { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { MainColor } from '@material-ui/core/styles/createTheme';
import { DatePickerWithTextInputProps } from './DatePickerWithTextInput.interface';
import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: 'rgb(0, 156, 166)'
    },
    secondary: {
      main: 'rgb(0, 156, 166)'
    }
  }
});

const commonStyle = (ntucHealth: MainColor, value: ParsableDate, disabled?: boolean) => {
  return {
    '&.Mui-disabled': {
      // backgroundColor: ntucHealth.grey200,
      '&:before': {
        borderBottom: 'unset'
      }
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(0, 0, 0, 0) !important'
    },
    '& .MuiInputBase-input': {
      paddingLeft: '11px'
    },
    width: '100%',
    border: `1px solid ${ntucHealth.charcoal300}`,
    borderRadius: '0 !important',
    '&.MuiFormControl-marginNormal': {
      marginTop: 0,
      marginBottom: 0,
      paddingLeft: '10px'
    },
    '& .MuiFilledInput-underline': {
      '& :after': {
        transition: 'transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;',
        borderBottom: '2px solid rgb(0, 156, 166);'
      }
    },
    // '& .MuiPickersMonth-monthButton': {
    //   '& .Mui-selected:hover': {
    //     backgroundColor: 'rgb(0, 156, 166) !important'
    //   }
    // },
    boxShadow: '0px 1px 1px rgba(152, 162, 179, 0.1), 0px 3px 2px rgba(152, 162, 179, 0.1)',
    '& > .MuiInputBase-root': {
      marginBottom: 0,
      border: `1px solid ${ntucHealth.charcoal300}`,
      backgroundColor: () => {
        if (!disabled && value && String(value).length > 0) {
          return 'white';
        }

        return ntucHealth.charcoal100;
      },
      height: 43,
      '&:hover': {
        '&:before': {
          borderBottom: 'unset'
        }
      },
      '&:before': {
        borderBottom: 'unset'
      }
    }
  };
};

export const useClasses = makeStyles<Theme, DatePickerWithTextInputProps, string>(({ ntucHealth }: Theme) => ({
  nh_customDatePickerField: (props: DatePickerWithTextInputProps) => ({
    ...commonStyle(ntucHealth, props.value, props.disabled)
  }),
  nh_customRequiredField: {
    color: ntucHealth.red900
  }
}));

export const useClassesStaticMode = makeStyles<Theme, Record<string, unknown>, string>(({ ntucHealth }: Theme) => {
  const windownWidth = window.innerWidth;
  let dayContentSize = {
    width: 0,
    height: 0
  };
  let fontSize = 0;
  let calendarTop = 0;
  let weekDayTop = 0;

  if (windownWidth <= 320) {
    dayContentSize = {
      width: 30,
      height: 30
    };

    fontSize = 14;
    calendarTop = 5;
    weekDayTop = 80;
  }

  if (windownWidth <= 375 && windownWidth > 320) {
    dayContentSize = {
      width: 35,
      height: 35
    };

    fontSize = 14;
    calendarTop = 5;
    weekDayTop = 80;
  }

  if (windownWidth <= 500 && windownWidth > 375) {
    dayContentSize = {
      width: 45,
      height: 45
    };

    fontSize = 16;
    calendarTop = 5;
    weekDayTop = 80;
  }

  if (windownWidth <= 600 && windownWidth > 500) {
    dayContentSize = {
      width: 30,
      height: 30
    };

    fontSize = 14;
    calendarTop = 5;
    weekDayTop = 80;
  }

  if (windownWidth <= 768 && windownWidth > 600) {
    dayContentSize = {
      width: 33,
      height: 33
    };

    fontSize = 16;
    calendarTop = 5;
    weekDayTop = 80;
  }

  if (windownWidth > 768) {
    dayContentSize = {
      width: 35,
      height: 35
    };

    fontSize = 16;
    calendarTop = 5;
    weekDayTop = 80;
  }

  return {
    nh_customDateStatic: {
      '& .CalendarDay__default': {
        color: 'inherit',
        border: 'none',
        // width: 10,

        '&:hover': {
          border: 'none',
          backgroundColor: 'unset'
        },

        '& > .nh_customDayContent': {
          width: dayContentSize.width,
          height: dayContentSize.height,
          marginLeft: 3,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',

          '&.disabled': {
            color: ntucHealth.charcoal500
          }
        }
      },

      '& .CalendarDay': {
        fontFamily: 'Open Sans',
        fontWeight: 300,
        fontSize: fontSize
      },

      '& .CalendarMonth_caption': {
        fontFamily: 'Open Sans',
        paddingBottom: 60
      },

      '& .DayPicker_weekHeader': {
        fontFamily: 'Open Sans',
        textTransform: 'uppercase',
        top: weekDayTop
      },

      '& .CalendarMonthGrid__horizontal': {
        top: calendarTop
      }
    }
  };
});
