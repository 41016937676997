import React, { FC } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';

// THEME
import ntucHealthTheme from 'config/theme';

// PROPS
import { ThemeProps } from './Theme.interface';

const NHThemeProvider: FC<ThemeProps> = ({ children }) => {
  return <ThemeProvider theme={ntucHealthTheme}>{children}</ThemeProvider>;
};

export default NHThemeProvider;
