import React, { FC } from 'react';
import MuiCollapse from '@material-ui/core/Collapse';

// INTERFACE
import { CollapseProps } from './Collapse.interface';

const Collapse: FC<CollapseProps> = ({ isOpen, children, testId }) => {
  return (
    <MuiCollapse data-testid={testId} in={isOpen}>
      {children}
    </MuiCollapse>
  );
};

export default Collapse;
