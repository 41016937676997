// BASE
import { Color } from 'interface';
import ntucHealth from 'config/theme/ntucHealth';

export const getColorByColorCode = (colorCode?: Color): string => {
  if (colorCode === 'secondary') {
    return ntucHealth.green600;
  }

  if (colorCode === 'teal') {
    return ntucHealth.teal700;
  }

  if (colorCode === 'white') {
    return ntucHealth.white;
  }

  if (colorCode === 'ice') {
    return ntucHealth.ice50;
  }

  if (colorCode === 'red') {
    return ntucHealth.red900;
  }

  if (colorCode === 'lilac') {
    return ntucHealth.lilac400;
  }

  if (colorCode === 'pine') {
    return ntucHealth.pine700;
  }

  if (colorCode === 'sienna') {
    return ntucHealth.sienna900;
  }

  if (colorCode === 'grey') {
    return ntucHealth.grey200;
  }

  return ntucHealth.charcoal800;
};

export const getRgba = (color: string, alpha: number): string =>
  color.replace(')', `, ${alpha})`).replace('rgb', 'rgba');
