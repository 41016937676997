import { makeStyles } from '@material-ui/core/styles';
import { MapProps, MarkerProps } from './Map.interface';
import type { Theme } from '@material-ui/core/styles/createTheme';
import ntucHealth from 'config/theme/ntucHealth';

export const useMarkerClasses = makeStyles<Theme, MarkerProps, string>(() => ({
  markerContainer: {
    cursor: 'pointer',
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    zIndex: 1,
    '&:hover': { zIndex: 2, color: ntucHealth.green600 }
  }
}));

export const useMapClasses = makeStyles<Theme, MapProps, string>(() => ({
  root: {
    position: 'relative',
    width: '100%'
  },
  image: {
    width: '100%',
    objectFit: 'cover'
  },
  absolute: {
    position: 'absolute'
  },
  label: {
    textAlign: 'center',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    width: '200px'
  },
  sw: {
    left: '23%',
    top: '53%'
  },
  nw: {
    left: '43%',
    top: '10%'
  },
  ct: {
    left: '49%',
    top: '85%'
  },
  ne: {
    left: '62%',
    top: '50%'
  },
  se: {
    left: '75%',
    top: '63%'
  },
  lsw: {
    left: '23%',
    top: '60%'
  },
  lnw: {
    left: '43%',
    top: '20%'
  },
  lct: {
    left: '52%',
    top: '76%'
  },
  lne: {
    left: '73%',
    top: '50%'
  },
  lse: {
    left: '80%',
    top: '68%'
  }
}));
