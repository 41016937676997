import { makeStyles } from '@material-ui/core/styles';
import type { Theme } from '@material-ui/core/styles/createTheme';
import { CircleProps } from './Circle.interface';

const useClasses = makeStyles<Theme, CircleProps, string>(({ ntucHealth }) => ({
  backgroundBar: {
    position: 'absolute'
  },
  wrapper: {
    position: 'relative',
    display: 'inline-flex',
    color: ntucHealth.charcoal300
  },
  textContainer: {
    inset: 0,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  mainBar: {
    strokeLinecap: 'round'
  }
}));

export default useClasses;
