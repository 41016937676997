import React, { FC } from 'react';

// INTERFACE
import { ButtonStackProps } from './ButtonStack.interface';

// COMPONENT
import { Grid } from 'components/Grid';
import { Div } from 'components/Div';
import { Typography } from 'components/Typography';
import { Button } from 'components/Button';

// STYLE
import useClasses from './ButtonStack.styles';

const BaseSelectTimeSlot: FC<ButtonStackProps> = ({ title, items, selectedItems, onChange }) => {
  const classes = useClasses({ title, items, selectedItems, onChange });

  return (
    <Div className={classes.nh_customButtonStackWrapper}>
      {title && (
        <Typography variant="h3" className="title">
          {title}
        </Typography>
      )}
      <Grid container spacing={2}>
        {items.map((slot, index) => {
          const isSelected = selectedItems.find((v) => v === slot.value);
          return (
            <Grid key={`${slot.value}-${index}`} item xs={12} sm={6} md={4}>
              <Button
                fullWidth
                className={isSelected ? 'selectedSlot' : 'slot'}
                variant={isSelected ? 'primary' : 'secondary'}
                isBlackBorder
                onClick={(): void => onChange && onChange(slot.value)}
              >
                <div>{slot.label}</div>
                {!slot.occupancy && typeof slot.capacity === 'number' && slot.capacity >= 0 && (
                  <div>{`(${slot.capacity} SLOTS AVAILABLE)`}</div>
                )}
                {typeof slot.occupancy === 'number' &&
                  slot.occupancy >= 0 &&
                  typeof slot.capacity === 'number' &&
                  slot.capacity >= 0 && <div>{`(${slot.occupancy} / ${slot.capacity} SLOTS AVAILABLE)`}</div>}
              </Button>
            </Grid>
          );
        })}
      </Grid>
    </Div>
  );
};

export default BaseSelectTimeSlot;
