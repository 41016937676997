import type { PaletteOptions } from '@material-ui/core/styles/createPalette';

// COLOR
import ntucHealth from './ntucHealth';

type Palette = PaletteOptions & {
  calendar: {
    acknowledged: string;
    cancelled: string;
    cancelledWithCharge: string;
    completed: string;
    leaves: string;
    lightLabel: string;
    listRowBorder: string;
    ongoing: string;
    publicHolidays: string;
    rowHeader: string;
    scheduled: string;
    softBooked: string;
    workingHours: string;
    greyBackground: string;
    lightGreyBorder: string;
  };
};

const defaultPalette: Palette = {
  primary: {
    main: ntucHealth.teal700
  },
  secondary: {
    main: ntucHealth.green600
  },
  text: {
    primary: ntucHealth.charcoal800,
    secondary: ntucHealth.green600
  },
  error: {
    main: ntucHealth.red900
  },
  background: {
    default: ntucHealth.charcoal100,
    paper: ntucHealth.white
  },
  divider: ntucHealth.grey200,
  calendar: ntucHealth.calendar
};

export default defaultPalette;
