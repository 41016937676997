require('react-big-calendar/lib/sass/styles.scss');
import moment from 'moment';
import React, { FC } from 'react';
import { Calendar as BigCalendar, Views, CalendarProps, momentLocalizer } from 'react-big-calendar';
import { EventType, ResourceType } from './Calendar.interface';

const ResourceCalendar: FC<Omit<CalendarProps<EventType, ResourceType>, 'localizer'>> = (props) => {
  const localizer = momentLocalizer(moment);

  return (
    <BigCalendar
      localizer={localizer}
      defaultView={Views.DAY}
      showMultiDayTimes={true}
      toolbar={false}
      step={15}
      {...props}
    />
  );
};

export default ResourceCalendar;
