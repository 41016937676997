import type { Theme } from '@material-ui/core/styles/createTheme';
import { RadioProps } from './Radio.interface';
import { makeStyles } from '@material-ui/core/styles';

const useClasses = makeStyles<Theme, RadioProps, string>(({ ntucHealth }) => ({
  nh_customRadioBoxRoot: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  nh_customRadioBoxIcon: {
    borderRadius: '50%',
    width: 20,
    height: 20,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: `2px auto ${ntucHealth.teal700}`,
      outlineOffset: 2
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5'
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)'
    }
  },
  nh_customRadioBoxCheckedIcon: {
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 20,
      height: 20,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""'
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3'
    }
  }
}));

export default useClasses;
