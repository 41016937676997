import { PriceCardProps } from './PriceCard.interface';
import React from 'react';
import { Card, CardActions, CardContent, CardHeader, Divider } from '@material-ui/core';
import { usePriceCardClasses } from './PriceCard.styles';
import { Div } from 'components/Div';
import { Grid } from 'components/Grid';
import { Typography } from 'components/Typography';
import { GradientColor } from 'components/GradientColor';

const PriceCard: React.FC<PriceCardProps> = ({ content, footer, sideContent, price, type, cardAction }) => {
  const classes = usePriceCardClasses({ content, footer, sideContent, price, type });

  const mainContent = (
    <>
      <Div className={classes.ribbonWrapper}>
        <Div className={classes.ribbon}>
          <GradientColor
            display="flex"
            justifyContent="center"
            alignItems="center"
            minWidth={120}
            padding={{ right: 40, left: 20 }}
            minHeight={40}
            colors={['teal', 'secondary']}
            borderRadius={{ topLeft: 5, bottomLeft: 0, topRight: 17, bottomRight: 5 }}
          >
            <Typography variant="h3" color="white">
              {type}
            </Typography>
          </GradientColor>
        </Div>
        <Div width={10} height={10} className={classes.after} />
      </Div>

      <CardHeader
        title={`$${price}`}
        titleTypographyProps={{ className: classes.customPriceCardHeader, variant: 'h1' }}
      />

      <Div width="50%" margin={{ all: 'auto' }}>
        <Divider className={classes.divider} />
      </Div>

      <CardContent>{content}</CardContent>

      <Div width="100%">
        <Divider className={classes.divider} />
      </Div>

      <Div margin={{ top: 10 }}>
        <Typography variant="caption">{footer}</Typography>
      </Div>
    </>
  );

  if (sideContent) {
    return (
      <Card className={classes.customPriceCard}>
        <Grid container>
          <Grid item xs={6}>
            {mainContent}
          </Grid>

          <Grid item xs={1}>
            <Div className={classes.customDivider} />
          </Grid>

          <Grid item xs={5}>
            <Div display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100%">
              {sideContent}
            </Div>
          </Grid>
        </Grid>
        <CardActions>{cardAction}</CardActions>
      </Card>
    );
  }

  return (
    <Card className={classes.customPriceCard}>
      {mainContent}
      <CardActions>{cardAction}</CardActions>
    </Card>
  );
};

export default PriceCard;
