/* eslint-disable no-unused-vars */
import React, { FC, ReactNode } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { IconButton } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import InputAdornment from '@material-ui/core/InputAdornment';
import DateRangeIcon from '@material-ui/icons/DateRange';
import ClearIcon from '@material-ui/icons/Clear';

// COMPONENT
import { Div } from 'components/Div';
import { Typography } from 'components/Typography';

// INTERFACE
import { DatePickerProps } from './DatePicker.interface';

// STYLE
import { useClasses } from './DatePicker.styles';

export const DatePicker: FC<DatePickerProps> = ({
  label,
  value,
  disabled,
  onChange,
  error,
  helperText,
  required,
  clearAll,
  onReset,
  testId,
  ...rest
}) => {
  const classes = useClasses({ value, disabled, ...rest });

  let errorComponent: ReactNode;
  let endAdornment: ReactNode;

  if (clearAll) {
    endAdornment = (
      <InputAdornment position="end">
        <IconButton
          onClick={(v) => {
            v.stopPropagation();

            if (onReset) {
              onReset();
            }
          }}
        >
          <ClearIcon fontSize="small" />
        </IconButton>
      </InputAdornment>
    );
  }

  const mainComponent = (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        data-testid={testId}
        className={classes.nh_customDatePickerField}
        value={value ? value : null}
        disabled={disabled}
        hiddenLabel={false}
        invalidDateMessage={null}
        keyboardIcon={<DateRangeIcon />}
        InputAdornmentProps={{ position: 'start', variant: 'standard' }}
        onChange={(v) => {
          onChange && onChange(v);
        }}
        error={error}
        helperText=""
        {...rest}
      />
    </MuiPickersUtilsProvider>
  );

  if (error) {
    errorComponent = (
      <Div margin={{ top: helperText ? 10 : 0 }}>
        <Typography color="red" variant="subtitle2">
          {helperText}
        </Typography>
      </Div>
    );
  }

  if (label && label.trim().length > 0) {
    return (
      <Div>
        <Div margin={{ bottom: 10 }}>
          <Typography bold>
            {label} {required ? <span className={classes.nh_customRequiredField}>*</span> : ''}
          </Typography>
        </Div>
        {mainComponent}
        {errorComponent}
      </Div>
    );
  }

  return (
    <>
      {mainComponent} {errorComponent}
    </>
  );
};
DatePicker.defaultProps = {
  value: new Date(),
  variant: 'inline',
  format: 'dd/MM/yyyy',
  openTo: 'date',
  disableToolbar: false,
  fullWidth: true
};
