// LOGO
import BaseHealthLogo from './assets/ntuc-health-logo.png';
import userDefaultImage from './assets/user.png';
import MenuLogo from './assets/menu.png';
import BaseHeroBg from './assets/hero_bg.png';
import BaseHealthLogoWhite from './assets/ntuc-health-logo-white.png';
import BaseCartIcon from './assets/cart_icon.png';
import BaseSgColorMap from './assets/sg_color_map.png';
import BaseActiveAging from './assets/active_ageing_1.svg';
import BaseActiveAgingActive from './assets/active_ageing_active.svg';
import BaseHomeCare from './assets/home_care.svg';
import BaseDayCare from './assets/day_care.svg';
import BaseDentiCare from './assets/denticare.svg';
import BaseDentiCareActive from './assets/denticare_active.svg';
import BaseHeroImg from './assets/hero.svg';
import BaseCommunitySupport from './assets/community_support.svg';
import BaseDefaultMarker from './assets/defaultMarker.png';
import BaseDefaultProductDetailIcon from './assets/ProductDetailIcon.png';

export const NTUCHealthLogo = BaseHealthLogo;
export const NTUCHealthLogoWhite = BaseHealthLogoWhite;
export const UserDefaultImage = userDefaultImage;
export const Menu = MenuLogo;
export const HeroBg = BaseHeroBg;
export const HeroImg = BaseHeroImg;
export const CartIcon = BaseCartIcon;
export const SgColorMap = BaseSgColorMap;
export const ActiveAging = BaseActiveAging;
export const ActiveAgingActive = BaseActiveAgingActive;
export const HomeCare = BaseHomeCare;
export const DayCare = BaseDayCare;
export const DentiCare = BaseDentiCareActive;
export const DentiCareActive = BaseDentiCare;
export const CommunitySupport = BaseCommunitySupport;
export const DefaultMarker = BaseDefaultMarker;
export const DefaultProductDetailIcon = BaseDefaultProductDetailIcon;

// FOOTER DATA
export { footerDefaultData } from './footer';

// NAVIGATION DATA
export { navigationDefaultData } from './navigation';
