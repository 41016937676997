import type { Theme } from '@material-ui/core/styles/createTheme';
import { TableHeadProps, TableBodyProps, TableCellProps, TableRowProps } from './Table.interface';
import { makeStyles } from '@material-ui/core/styles';

const useClassesTableHead = makeStyles<Theme, TableHeadProps, string>(() => ({
  nh_customTableHead: {}
}));

const useClassesTableBody = makeStyles<Theme, TableBodyProps, string>(() => ({
  nh_customTableBody: {}
}));

const useClassesTableRow = makeStyles<Theme, TableRowProps, string>(({ ntucHealth }) => ({
  nh_customTableRow: {
    '&.MuiTableRow-head': {
      '& > .MuiTableCell-head': {
        fontWeight: 'bold',
        color: ntucHealth.charcoal700,
        backgroundColor: ntucHealth.grey200,
        padding: 0,

        '&:first-child': {
          borderTopLeftRadius: (props: TableRowProps) => props.borderRadius,
          borderBottomLeftRadius: (props: TableRowProps) => props.borderRadius
        },

        '&:last-child': {
          borderTopRightRadius: (props: TableRowProps) => props.borderRadius,
          borderBottomRightRadius: (props: TableRowProps) => props.borderRadius
        }
      }
    },
    '&.MuiTableRow-root': {
      '&:first-child': {
        '& > .MuiTableCell-body': {
          paddingTop: (props: TableRowProps) => (props.spacing ? props.spacing : 0),
          paddingBottom: (props: TableRowProps) => (props.spacing ? props.spacing / 2 : 0)
        }
      },

      '& > .MuiTableCell-body': {
        padding: 0,
        paddingTop: (props: TableRowProps) => (props.spacing ? props.spacing / 2 : 0),
        paddingBottom: (props: TableRowProps) => (props.spacing ? props.spacing / 2 : 0),

        '&:first-child': {
          '& > .table-cell-wrapper': {
            borderLeft: `2px solid ${ntucHealth.grey200}`,
            borderTopLeftRadius: (props: TableRowProps) => props.borderRadius,
            borderBottomLeftRadius: (props: TableRowProps) => props.borderRadius
          }
        },

        '&:last-child': {
          '& > .table-cell-wrapper': {
            borderRight: `2px solid ${ntucHealth.grey200}`,
            borderTopRightRadius: (props: TableRowProps) => props.borderRadius,
            borderBottomRightRadius: (props: TableRowProps) => props.borderRadius
          }
        },

        '& > .table-cell-wrapper': {
          padding: 0,
          borderBottom: `2px solid ${ntucHealth.grey200}`,
          borderTop: (props: TableRowProps) => (props.spacing === 0 ? 'unset' : `2px solid ${ntucHealth.grey200}`)
        }
      }
    }
  }
}));

const useClassesTableCell = makeStyles<Theme, TableCellProps, string>(({ ntucHealth }) => ({
  nh_customTableCell: {
    '&.MuiTableCell-body': {
      backgroundColor: ntucHealth.white,
      backgroundClip: 'content-box',

      '& > .table-cell-wrapper': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: (props: TableCellProps) => {
          if (props.align === 'center') {
            return 'center';
          }

          if (props.align === 'right') {
            return 'flex-end';
          }

          return 'flex-start';
        }
      }
    }
  }
}));

export { useClassesTableHead, useClassesTableBody, useClassesTableCell, useClassesTableRow };
