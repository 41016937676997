import React, { FC, useMemo, useState } from 'react';
import { MapProps, MarkerProps } from './Map.interface';
import { useMapClasses, useMarkerClasses } from './Map.styles';
// Library
import GoogleMapReact from 'google-map-react';
import { SgColorMap } from 'data';
import { Typography } from 'components/Typography';
import { isMdScreen, isSmScreen, isXSScreen } from 'hooks/MediaQuery';
import { DefaultMarker } from 'data';

const defaultCenter = { lat: 1.352083, lng: 103.819839 };
const defaultZoom = 11;

const Marker: FC<MarkerProps> = (props) => {
  const classes = useMarkerClasses(props);

  return (
    <div className={classes.markerContainer} {...props}>
      {props.marker}
    </div>
  );
};

const Map: FC<MapProps> = ({
  marker,
  apiKey,
  type = 'color',
  onColorMapChildClick,
  colorMapImageSrc = SgColorMap,
  ggMapCentersData,
  markerRegions
}) => {
  const xsScreen = isXSScreen();
  const smScreen = isSmScreen();
  const mdScreen = isMdScreen();

  const labelFontsize = useMemo(() => {
    if (xsScreen) {
      return 10;
    }

    if (smScreen) {
      return 15;
    }

    if (mdScreen) {
      return 15;
    }

    return 25;
  }, [xsScreen, smScreen, mdScreen]);

  const markerSize = useMemo(() => {
    if (xsScreen) {
      return '10px';
    }

    if (smScreen) {
      return '15px';
    }

    return '20px';
  }, [xsScreen, smScreen, mdScreen]);

  const defaultMarker = marker || <img src={DefaultMarker} width={markerSize} />;

  const [coords, setCoords] = useState(defaultCenter);
  const [zoom, setZoom] = useState(defaultZoom);
  const classes = useMapClasses({ apiKey, ggMapCentersData });

  const labels = [
    { name: 'South West', id: 'lsw' },
    { name: 'North West', id: 'lnw' },
    { name: 'Central', id: 'lct' },
    { name: 'North East', id: 'lne' },
    { name: 'South East', id: 'lse' }
  ];

  if (type === 'google') {
    return (
      <GoogleMapReact
        bootstrapURLKeys={{ key: apiKey, region: 'sg' }}
        defaultCenter={defaultCenter}
        center={coords}
        defaultZoom={defaultZoom}
        zoom={zoom}
        options={{ zoomControl: true }}
        onChange={(e) => {
          setCoords({ lat: e.center.lat, lng: e.center.lng });
          setZoom(e.zoom);
        }}
        onChildClick={(id) => {
          const selectedCenter = ggMapCentersData.find((c) => c.id === id);
          if (selectedCenter) {
            setCoords(selectedCenter.coord);
            setZoom(16);
          }
        }}
      >
        {ggMapCentersData.map((c) => (
          <Marker key={c.id} lat={c.coord.lat} lng={c.coord.lng} marker={marker} />
        ))}
      </GoogleMapReact>
    );
  }

  return (
    <div className={classes.root}>
      <img src={colorMapImageSrc} alt="" className={classes.image} />
      {markerRegions?.map((m) => (
        <div
          onClick={() => onColorMapChildClick && onColorMapChildClick(m)}
          key={m}
          className={`${classes.absolute} ${classes[m]}`}
        >
          <Marker marker={defaultMarker} />
        </div>
      ))}
      {labels.map((l) => (
        <div key={l.id} className={`${classes.absolute} ${classes[l.id]}`}>
          <Typography style={{ fontSize: labelFontsize }} className={classes.label} fontWeight="bold">
            {l.name.toUpperCase()}
          </Typography>
        </div>
      ))}
    </div>
  );
};

export default Map;
