import React, { FC, useMemo } from 'react';
import clsx from 'clsx';
import MuiCard from '@material-ui/core/Card';
import MuiCardHeader from '@material-ui/core/CardHeader';
import MuiCardActions from '@material-ui/core/CardActions';
import MuiCardContent from '@material-ui/core/CardContent';

// DIV
import { isFromSmScreen } from 'hooks/MediaQuery';
import { Grid } from 'components/Grid';
import { Div } from 'components/Div';
import { Typography } from 'components/Typography';

// INTERFACE
import { CardProps, CardHeaderProps, CardFooterProps, CardBodyProps, CardStatusProps } from './Card.interface';

// STYLE
import { useCardHeaderClasses, useCardClasses, useCardFooterClasses, useCardBodyClasses } from './Card.style';

const Card: FC<CardProps> = ({ children, className, testId }) => {
  const classes = useCardClasses({ children });

  return (
    <MuiCard data-testid={testId} className={clsx(className, classes.nh_customCard)}>
      {children}
    </MuiCard>
  );
};

const CardHeader: FC<CardHeaderProps> = ({
  titleTypographyProps,
  withBorder,
  title,
  subHeader,
  headerStatus,
  backgroundColor,
  onHeaderClick,
  testId
}) => {
  const fromSmScreen = isFromSmScreen();
  const classes = useCardHeaderClasses({ withBorder, title, subHeader, headerStatus, backgroundColor, onHeaderClick });

  return (
    <MuiCardHeader
      data-testid={testId}
      className={classes.nh_customCardHeader}
      titleTypographyProps={
        typeof title === 'string' ? { variant: 'h3', ...titleTypographyProps } : { ...titleTypographyProps }
      }
      title={
        <Grid alignItems="center" container spacing={1}>
          <Grid item xs={12} sm={subHeader ? 7 : 12}>
            {title}
          </Grid>
          {subHeader && (
            <Grid item xs={12} sm={5} style={fromSmScreen ? { textAlign: 'right' } : {}}>
              {subHeader}
            </Grid>
          )}
        </Grid>
      }
      onClick={onHeaderClick}
    />
  );
};

const CardBody: FC<CardBodyProps> = ({ children, testId }) => {
  const classes = useCardBodyClasses({ children });

  return (
    <MuiCardContent data-testid={testId} className={classes.nh_customCardBody}>
      {children}
    </MuiCardContent>
  );
};

const CardFooter: FC<CardFooterProps> = ({ children, testId }) => {
  const classes = useCardFooterClasses({ children });

  return (
    <MuiCardActions data-testid={testId} className={classes.nh_customCardFooter}>
      {children}
    </MuiCardActions>
  );
};

const CardStatus: FC<CardStatusProps> = ({ status, customText }) => {
  const label = useMemo(() => {
    if (status === 'cancelled') {
      return <Typography>{customText || 'Cancelled'}</Typography>;
    }

    if (status === 'completed') {
      return <Typography color="pine">{customText || 'Completed'}</Typography>;
    }

    if (status === 'confirmed') {
      return <Typography color="teal">{customText || 'Confirmed'}</Typography>;
    }

    if (status === 'missed') {
      return <Typography color="red">{customText || 'Missed'}</Typography>;
    }

    if (status === 'requested') {
      return <Typography color="sienna">{customText || 'Requested'}</Typography>;
    }

    if (status === 'toBook') {
      return <Typography color="lilac">{customText || 'To Book'}</Typography>;
    }
  }, [status]);

  return (
    <Div
      display="inline-flex"
      padding={{ top: 2, bottom: 2, left: 20, right: 20 }}
      backgroundColor="grey"
      borderRadius={{ all: 8 }}
    >
      {label}
    </Div>
  );
};

export { Card, CardHeader, CardBody, CardFooter, CardStatus };
