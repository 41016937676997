import React, { FC } from 'react';

// INTERFACE
import { NavigationProps } from './Navigation.interface';

// HOOK
import { isFromMdScreen } from 'hooks/MediaQuery';

// DEFAULT DATA
import { navigationDefaultData } from 'data';

// MODULE
import DesktopNavigation from './Navigation.Desktop';
import MobileNavigation from './Navigation.Mobile';

const Navigation: FC<NavigationProps> = ({
  labels = [],
  notifications,
  userMenu,
  isAuthenticated,
  onBrandClick,
  onLogin,
  onLogout,
  onSelectProfile,
  user,
  CustomeView
}) => {
  const isMdScreen = isFromMdScreen();

  if (isMdScreen) {
    return (
      <DesktopNavigation
        onBrandClick={onBrandClick}
        labels={labels}
        isAuthenticated={isAuthenticated}
        onLogin={onLogin}
        userMenu={userMenu}
        notifications={notifications}
        user={user}
        onSelectProfile={onSelectProfile}
        CustomeView={CustomeView}
      />
    );
  }

  return (
    <MobileNavigation
      onBrandClick={onBrandClick}
      labels={labels}
      isAuthenticated={isAuthenticated}
      onLogin={onLogin}
      onLogout={onLogout}
      userMenu={userMenu}
      notifications={notifications}
      user={user}
      onSelectProfile={onSelectProfile}
      CustomeView={CustomeView}
    />
  );
};
Navigation.defaultProps = {
  ...navigationDefaultData
};

export default Navigation;
