import type { Theme } from '@material-ui/core/styles/createTheme';
import { ImageProps } from './Image.interface';
import { makeStyles } from '@material-ui/core/styles';

const useClasses = makeStyles<Theme, Pick<ImageProps, 'width'>, string>(() => ({
  nh_customImageWrapper: {
    backgroundColor: 'transparent'
  },
  image: {
    width: (props: Pick<ImageProps, 'width'>) => {
      return !props.width ? 'unset' : `${props.width}%`;
    },
    height: 'auto'
  }
}));

export default useClasses;
