import React, { FC } from 'react';
import MuiGrid from '@material-ui/core/Grid';

// INTERFACE
import { GridProps } from './Grid.interface';

const Grid: FC<GridProps> = ({ testId, ...props }) => {
  return <MuiGrid data-testid={testId} {...props} />;
};

export default Grid;
