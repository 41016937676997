import type { Theme } from '@material-ui/core/styles/createTheme';
import { TabsProps } from './Tabs.interface';
import { makeStyles } from '@material-ui/core/styles';

const useClasses = makeStyles<Theme, Pick<TabsProps, 'widthBorder'>, string>(({ ntucHealth }) => ({
  nh_customTabs: {
    borderBottom: (props: Pick<TabsProps, 'widthBorder'>) => {
      if (props.widthBorder) {
        return `2px solid ${ntucHealth.grey200}`;
      }
    }
  }
}));

export default useClasses;
