import React, { FC, useMemo } from 'react';
import { Tabs as MuiTabs, Tab } from '@material-ui/core';

// INTERFACE
import { TabsProps } from './Tabs.interface';

// COMPONENT
import { Div } from 'components/Div';

// STYLE
import useClasses from './Tabs.styles';

const Tabs: FC<TabsProps> = ({ widthBorder, items, value, onChange, testId, ...rest }) => {
  const className = useClasses({ widthBorder });

  const tabContent = useMemo(() => {
    const item = items.find((v, i) => Number(i) === Number(value));

    if (!item) return null;

    return item.component;
  }, [value]);

  if (widthBorder) {
    return (
      <Div borderColor={{ all: 'grey' }} borderWidth={{ all: 2 }} borderRadius={{ all: 4 }}>
        <MuiTabs
          data-testid={testId}
          className={className.nh_customTabs}
          value={value}
          onChange={(v, number) => onChange && onChange(number)}
          {...rest}
        >
          {items.map((v, idx) => (
            <Tab key={idx} label={v.label} />
          ))}
        </MuiTabs>
        {tabContent}
      </Div>
    );
  }

  return (
    <Div>
      <MuiTabs data-testid={testId} value={value} onChange={(v, number) => onChange && onChange(number)} {...rest}>
        {items.map((v, idx) => (
          <Tab key={idx} label={v.label} />
        ))}
      </MuiTabs>
      {tabContent}
    </Div>
  );
};

export default Tabs;
