import React, { FC } from 'react';
import { Grid, AppBar } from '@material-ui/core';
import { GrDown, GrUp } from 'react-icons/gr';
import Popover from '@material-ui/core/Popover';

// INTERFACE
import { NavigationProps } from './Navigation.interface';
import { NavigationLabel } from 'interface';

// // STYLE
import useClasses from './Navigation.style';

// COMPONENT
import Typography from 'components/Typography/Typo';
import { Div } from 'components/Div';
import { Image } from 'components/Image';
import { Button } from 'components/Button';
import { Avatar } from 'components/Avatar';
import { GradientColor } from 'components/GradientColor';

// NAVIGATION MODULE
import { NavigationItemDropDown, UserRow, ManageUserProfile, CollapseMenuItem } from './Navigation.module';

// HOOK
import { betweenCustomScreen } from 'hooks/MediaQuery';

// DEFAULT DATA
import { NTUCHealthLogoWhite } from 'data';

const NavigationItem: FC<Pick<NavigationLabel, 'label' | 'onClick'>> = ({ label, onClick }) => {
  const subTitle1 = label.split(' ')[0];
  const subTitle2 = label.split(' ')[1];

  const betweenScreen = betweenCustomScreen(970, 1215);

  if (betweenScreen) {
    return (
      <Grid item>
        <Div display="flex" alignItems="center" onCLick={onClick}>
          <Typography variant="body2" bold color="white">
            {subTitle1}
          </Typography>
          <Typography variant="body2" bold color="white">
            {subTitle2}
          </Typography>
        </Div>
      </Grid>
    );
  }

  return (
    <Grid item>
      <Typography variant="body2" bold color="white" onClick={onClick} hoverColor="secondary">
        {label}
      </Typography>
    </Grid>
  );
};

const DesktopNavigation: FC<NavigationProps> = ({
  labels = [],
  userMenu = [],
  notifications,
  isAuthenticated,
  onLogin,
  onBrandClick,
  user,
  onSelectProfile,
  CustomeView
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const baseClasses = useClasses({});
  let classForUserButton;

  const betweenScreen = betweenCustomScreen(970, 1031);

  if (betweenScreen) {
    classForUserButton = baseClasses.userWrapperWithWidth;
  } else {
    classForUserButton = baseClasses.userWrapper;
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="sticky">
      <GradientColor
        colors={['teal']}
        padding={{ right: 40, left: 40 }}
        overflowY="hidden"
        overflowX="hidden"
        minHeight={90}
        display="flex"
        flexDirection="row"
      >
        <Div display="flex" justifyContent="center" onCLick={onBrandClick}>
          <Image src={NTUCHealthLogoWhite} />
        </Div>
        <Grid className={baseClasses.navigationWrapper} container spacing={2}>
          {labels.map((v, i) => {
            return <NavigationItem key={i} label={v.label} onClick={v.onClick} />;
          })}
          {CustomeView}
          {!isAuthenticated && (
            <Grid item>
              <Div margin={{ right: 20 }}>
                <Typography onClick={onLogin} hoverColor="secondary" bold color="white" variant="body2">
                  LOGIN / SIGN UP
                </Typography>
              </Div>
            </Grid>
          )}
          {isAuthenticated && user && (
            <Grid item className={classForUserButton}>
              <Button
                aria-controls="user-menu"
                aria-haspopup="true"
                disableRipple
                fullHeight
                withoutBorder
                variant="secondary"
                onClick={handleClick}
              >
                <Avatar url={user.avatar} />
                <Div margin={{ left: 10 }}>
                  <Typography bold color="white" variant="body2" align="center">
                    {user.fullName.toUpperCase()}
                  </Typography>
                </Div>
                <Div margin={{ left: 10 }} display="flex" alignItems="center" className={baseClasses.icon}>
                  {anchorEl ? <GrUp /> : <GrDown />}
                </Div>
              </Button>
              <Popover
                id="user-menu"
                anchorEl={anchorEl}
                elevation={0}
                getContentAnchorEl={null}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}
                className={baseClasses.menuWrapper}
              >
                <Div width={300}>
                  {userMenu.map((v, i) => {
                    if (v.label === 'NOTIFICATIONS') {
                      return (
                        <NavigationItemDropDown
                          key={i}
                          label={v.label}
                          onClick={v.onClick}
                          notifications={notifications}
                        />
                      );
                    }

                    if (v.subItems && v.subItems.length > 0) {
                      return (
                        <CollapseMenuItem
                          key={i}
                          label={v.label}
                          subItems={v.subItems}
                          onClick={() => {
                            handleClose();
                          }}
                        />
                      );
                    }

                    return (
                      <NavigationItemDropDown
                        key={i}
                        label={v.label}
                        onClick={() => {
                          handleClose();
                          if (v && v.onClick) {
                            v.onClick();
                          }
                        }}
                      />
                    );
                  })}
                  {user.userMembers && user.userMembers.length > 0 && (
                    <Div
                      backgroundColor="grey"
                      height={50}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      flexDirection="row"
                      padding={{ all: 10 }}
                    >
                      <Typography color="primary" variant="body2" align="center">
                        View profile of
                      </Typography>
                    </Div>
                  )}
                  {user.userMembers &&
                    user.userMembers.map((v, i) => {
                      return <UserRow key={`${v.id} - ${i}`} user={v} onSelectProfile={onSelectProfile} color="grey" />;
                    })}
                  {user.userMembers && user.userMembers.length > 0 ? (
                    <ManageUserProfile onSelectProfile={onSelectProfile} color="grey" />
                  ) : null}
                </Div>
              </Popover>
            </Grid>
          )}
        </Grid>
      </GradientColor>
    </AppBar>
  );
};

export default DesktopNavigation;
