import React, { FC } from 'react';

// PROPS
import { ImageProps } from './Image.interface';

// STYLE
import useClasses from './Image.styles';

const Image: FC<ImageProps> = ({ width, src, testId }) => {
  const baseClasses = useClasses({ width });

  return <img data-testid={testId} src={src} className={baseClasses.image} />;
};

export default Image;
