import { Fade, Modal, IconButton } from '@material-ui/core';
import React, { FC } from 'react';
import { FullscreenModalProps } from './FullscreenModal.interface';
import useFullscreenModalClasses from './FullscreenModal.styles';
import CloseIcon from '@material-ui/icons/Close';

const FullscreenModal: FC<FullscreenModalProps> = ({
  open,
  onClose,
  children,
  BackdropProps = { timeout: 500, style: { backgroundColor: 'transparent' } },
  closeAfterTransition = true,
  ...props
}) => {
  const classes = useFullscreenModalClasses({});

  return (
    <Modal
      open={open}
      onClose={onClose}
      BackdropProps={BackdropProps}
      closeAfterTransition={closeAfterTransition}
      {...props}
    >
      <Fade in={open}>
        <div className={classes.modal}>
          {children}
          <IconButton onClick={onClose} className={classes.closeBtn}>
            <CloseIcon fontSize="large" />
          </IconButton>
        </div>
      </Fade>
    </Modal>
  );
};

export default FullscreenModal;
