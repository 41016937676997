/* eslint-disable no-unused-vars */
import React, { FC, ReactNode } from 'react';

// COMPONENT

// INTERFACE
import { DatePickerWithTextInputProps } from './DatePickerWithTextInput.interface';

// STYLE
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useClasses, theme } from './DatePickerWithTextInput.styles';
import { Div } from 'components/Div';
import { Typography } from 'components/Typography';
import { TextField } from 'components/TextField';
import { ThemeProvider } from '@mui/material/styles';

export const DatePickerWithTextInput: FC<DatePickerWithTextInputProps> = ({
  value,
  disabled,
  error,
  helperText,
  label,
  name,
  fullWidth,
  required,
  placeholder,
  onChange,
  ...rest
}) => {
  const classes = useClasses({ value, disabled, ...rest });
  let errorComponent: ReactNode;

  const mainComponent = (
    <Div>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            className={`${classes.nh_customDatePickerField} ${classes.picker} ${
              fullWidth ? 'MuiFormControl-fullWidth' : ''
            }`}
            value={value ? value : null}
            onChange={(v) => {
              if (v) {
                onChange && onChange(v);
              }
            }}
            views={['year', 'month', 'day']}
            slotProps={{
              textField: (params) => (
                <TextField fullWidth placeholder={placeholder} name={name} type="text" {...params} />
              )
            }}
            {...rest}
          />
        </LocalizationProvider>
      </ThemeProvider>
    </Div>
  );

  if (error) {
    errorComponent = (
      <Div margin={{ top: helperText ? 10 : 0 }}>
        <Typography color="red" variant="subtitle2">
          {helperText}
        </Typography>
      </Div>
    );
  }

  if (label && label.trim().length > 0) {
    return (
      <Div>
        <Div margin={{ bottom: 10 }}>
          <Typography bold>
            {label} {required ? <span className={classes.nh_customRequiredField}>*</span> : ''}
          </Typography>
        </Div>
        {mainComponent}
        {errorComponent}
      </Div>
    );
  }
};
