import { css } from '@emotion/react';
import styled from '@emotion/styled';
import palette from '../../config/theme/palette';
import { AppointmentStatus } from './Calendar.interface';

// Styles are expect to be clean up in other ticket when consolidating all the styles into ui kit.
const spacing = (...factors: number[]) => {
  const spaceBasis = 8;
  const spaces = factors.slice(0, 4).map((f) => {
    const m = Math.abs(f % 1);
    let fx = f;

    // check if decimal number is not exactly 0.5, do ceil or floor;
    if (m !== 0.5) {
      fx = m < 0.5 ? Math.floor(f) : Math.ceil(f);
    }

    return `${fx * spaceBasis}px`;
  });

  return spaces.join(' ');
};

const pxToRem = (value: number) => {
  const baseFontSize = 14; // 0.875rem

  return `${value / baseFontSize}rem`;
};

type AvailabilityType = 'LEAVE' | 'WORK';

type LeaveSubType = 'HALF_DAY_PM' | 'HALF_DAY_AM' | 'FULL_DAY' | 'CUSTOM';

type BigCalendarLegends =
  | AppointmentStatus
  | AvailabilityType
  | LeaveSubType
  | 'PUBLIC_HOLIDAY'
  | 'CALENDAR_CUSTOM'
  | string;

const colors = palette;
export const statusColorMap: Record<BigCalendarLegends, string> = {
  ACKNOWLEDGED: colors.calendar.acknowledged,
  CANCELLED: colors.calendar.cancelled,
  CANCELLED_WITH_CHARGE: colors.calendar.cancelledWithCharge,
  COMPLETED: colors.calendar.completed,
  DELETED: colors.calendar.cancelled,
  LEAVE: colors.calendar.leaves,
  ONGOING: colors.calendar.ongoing,
  RESCHEDULE: colors.calendar.cancelled,
  SCHEDULED: colors.calendar.scheduled,
  SOFT_BOOKED: colors.calendar.softBooked,
  WORK: colors.calendar.workingHours,
  PUBLIC_HOLIDAY: colors.calendar.publicHolidays
};

const getBorderRadius = (status: string) => {
  return ['PUBLIC_HOLIDAY'].indexOf(status) === -1 ? '25px' : '0px';
};

export const CalendarLegends = styled.ul`
  display: flex;
  list-style: none;
`;

export const CalendarLegend = styled.li<{ status: BigCalendarLegends }>`
  position: relative;
  padding-left: ${spacing(2.5)};
  margin-left: ${spacing(2)};
  font-size: ${pxToRem(14)};
  color: ${(props) => statusColorMap[props.status]};

  &:before {
    position: absolute;
    top: 3px;
    left: 0;
    width: 14px;
    height: 14px;
    background-color: ${(props) => statusColorMap[props.status]};
    border-radius: ${(props) => getBorderRadius(props.status)};
    content: '';
  }
`;

export const CalendarTypeTitle = styled.span`
  padding-left: ${spacing(1)};
`;

export const CalendarDate = styled.span`
  font-size: ${pxToRem(14)};
`;

export const calendarControls = css`
  box-shadow: none;
  border-radius: 0;
  text-transform: none;
  margin-right: ${spacing(1)};
`;

export const legendList = css`
  display: inherit;
`;

export const currentMonth = css`
  user-select: none;
`;

export const CalendarView = styled.div<{ view: string }>`
  display: flex;
  flex-grow: 0;
  overflow: auto;
  height: ${(props) => (props.view === 'month' ? '790px' : 'auto')};

  .rbc-btn {
    color: inherit;
    font: inherit;
    margin: 0;
  }

  button.rbc-btn {
    overflow: visible;
    text-transform: none;
    -webkit-appearance: button;
    cursor: pointer;
  }

  button[disabled].rbc-btn {
    cursor: not-allowed;
  }

  button.rbc-input::-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  .rbc-calendar {
    box-sizing: border-box;
    height: 100%;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: stretch;
  }

  .rbc-calendar *,
  .rbc-calendar *:before,
  .rbc-calendar *:after {
    box-sizing: inherit;
  }

  .rbc-abs-full,
  .rbc-row-bg {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .rbc-ellipsis,
  .rbc-event-label,
  .rbc-row-segment .rbc-event-content,
  .rbc-show-more {
    display: block;
    overflow: hidden;
    font-size: ${pxToRem(12.25)};
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .rbc-rtl {
    direction: rtl;
  }

  .rbc-off-range {
    color: #999999;
  }

  .rbc-off-range-bg {
    background: ${colors.calendar.greyBackground};
  }

  .rbc-header {
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
    text-transform: uppercase;
    vertical-align: middle;
    font-weight: bold;
    flex: 1 0 0%;
    padding: 0 3px;
    font-size: 90%;
    min-height: 0;
    padding: ${spacing(1, 0, 0)};
    background-color: ${colors.background?.paper};
  }

  .rbc-header + .rbc-header {
    border-left: 1px solid ${colors.calendar.lightGreyBorder};
  }
  .rbc-rtl .rbc-header + .rbc-header {
    border-left-width: 0;
    border-right: 1px solid ${colors.calendar.lightGreyBorder};
  }
  .rbc-header > a,
  .rbc-header > a:active,
  .rbc-header > a:visited {
    color: inherit;
    text-decoration: none;
  }

  .rbc-row-content {
    position: relative;
    user-select: none;

    z-index: 4;
  }

  .rbc-today {
    background-color: ${colors.calendar.greyBackground};
  }

  .rbc-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    font-size: ${pxToRem(16)};
  }
  .rbc-toolbar .rbc-toolbar-label {
    flex-grow: 1;
    padding: 0 10px;
    text-align: center;
  }
  .rbc-toolbar button {
    color: #373a3c;
    display: inline-block;
    margin: 0;
    text-align: center;
    vertical-align: middle;
    background: none;
    background-image: none;
    border: 1px solid #ccc;
    padding: 0.375rem 1rem;
    border-radius: 4px;
    line-height: normal;
    white-space: nowrap;
  }
  .rbc-toolbar button:active,
  .rbc-toolbar button.rbc-active {
    background-image: none;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    background-color: #e6e6e6;
    border-color: #adadad;
  }
  .rbc-toolbar button:active:hover,
  .rbc-toolbar button:active:focus,
  .rbc-toolbar button.rbc-active:hover,
  .rbc-toolbar button.rbc-active:focus {
    color: #373a3c;
    background-color: #d4d4d4;
    border-color: #8c8c8c;
  }
  .rbc-toolbar button:focus {
    color: #373a3c;
    background-color: #e6e6e6;
    border-color: #adadad;
  }
  .rbc-toolbar button:hover {
    color: #373a3c;
    background-color: #e6e6e6;
    border-color: #adadad;
  }

  .rbc-btn-group {
    display: inline-block;
    white-space: nowrap;
  }
  .rbc-btn-group > button:first-of-type:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .rbc-btn-group > button:last-child:not(:first-of-type) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .rbc-rtl .rbc-btn-group > button:first-of-type:not(:last-child) {
    border-radius: 4px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .rbc-rtl .rbc-btn-group > button:last-child:not(:first-of-type) {
    border-radius: 4px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .rbc-btn-group > button:not(:first-of-type):not(:last-child) {
    border-radius: 0;
  }
  .rbc-btn-group button + button {
    margin-left: -1px;
  }
  .rbc-rtl .rbc-btn-group button + button {
    margin-left: 0;
    margin-right: -1px;
  }
  .rbc-btn-group + .rbc-btn-group,
  .rbc-btn-group + button {
    margin-left: 10px;
  }

  .rbc-event {
    box-sizing: border-box;
    box-shadow: none;
    margin: 0;
    padding: 2px 4px;
    border-radius: 5px;
    cursor: pointer;
    width: calc(100% - 16px);
    color: ${colors.background?.paper};

    &.acknowledged {
      background-color: ${colors.calendar.acknowledged};
    }

    &.cancelled {
      background-color: ${colors.calendar.cancelled};
    }

    &.cancelled_with_charge {
      background-color: ${colors.calendar.cancelled};
    }

    &.completed {
      background-color: ${colors.calendar.completed};
    }

    &.ongoing {
      background-color: ${colors.calendar.ongoing};
    }

    &.scheduled {
      background-color: ${colors.calendar.scheduled};
    }

    &.soft_booked {
      background-color: ${colors.calendar.softBooked};
    }

    &.leave {
      background-color: ${colors.calendar.leaves};
    }

    &.work {
      background-color: ${colors.calendar.workingHours};
    }
  }
  .rbc-slot-selecting .rbc-event {
    cursor: inherit;
    pointer-events: none;
  }
  .rbc-event.rbc-selected {
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
  }
  .rbc-event:focus {
    outline: none;
  }

  .rbc-event-label {
    font-size: 80%;
  }

  .rbc-event-overlaps {
    box-shadow: -1px 1px 5px 0px rgba(51, 51, 51, 0.5);
  }

  .rbc-event-continues-prior {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .rbc-event-continues-after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .rbc-event-continues-earlier {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .rbc-event-continues-later {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .rbc-row {
    display: flex;
    flex-direction: row;

    &.rbc-time-header-cell {
      .rbc-header + .rbc-header {
        border: none;
      }
    }
  }

  .rbc-row-segment {
    padding: 0 1px 1px 1px;
  }

  .rbc-selected-cell {
    background-color: rgba(0, 0, 0, 0.1);
  }

  .rbc-show-more {
    background-color: rgba(255, 255, 255, 0.3);
    z-index: 4;
    font-weight: bold;
    font-size: 85%;
    height: auto;
    line-height: normal;
  }

  .rbc-month-view {
    position: relative;
    border: 1px solid ${colors.calendar.lightGreyBorder};
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
    width: 100%;
    user-select: none;
  }

  .rbc-month-header {
    display: flex;
    flex-direction: row;
  }

  .rbc-month-row {
    display: flex;
    position: relative;
    flex-direction: column;
    flex: 1 0 0;
    flex-basis: 0px;
    overflow: hidden;
    height: 100%;
  }
  .rbc-month-row + .rbc-month-row {
    border-top: 1px solid ${colors.calendar.lightGreyBorder};
  }

  .rbc-date-cell {
    flex: 1 1 0;
    min-width: 0;
    padding: ${spacing(0.5, 0)};
    text-align: center;
  }
  .rbc-date-cell.rbc-now {
    font-weight: bold;
  }
  .rbc-date-cell > a,
  .rbc-date-cell > a:active,
  .rbc-date-cell > a:visited {
    color: inherit;
    text-decoration: none;
  }

  .rbc-row-bg {
    display: flex;
    flex-direction: row;
    flex: 1 0 0;
    overflow: hidden;
  }

  .rbc-day-bg {
    flex: 1 0 0%;
  }
  .rbc-day-bg + .rbc-day-bg {
    border-left: 1px solid ${colors.calendar.lightGreyBorder};
  }
  .rbc-rtl .rbc-day-bg + .rbc-day-bg {
    border-left-width: 0;
    border-right: 1px solid ${colors.calendar.lightGreyBorder};
  }

  .rbc-overlay {
    position: absolute;
    z-index: 5;
    border: 1px solid #e5e5e5;
    background-color: #fff;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
    padding: 10px;
  }
  .rbc-overlay > * + * {
    margin-top: 1px;
  }

  .rbc-overlay-header {
    border-bottom: 1px solid #e5e5e5;
    margin: -10px -10px 5px -10px;
    padding: 2px 10px;
  }

  .rbc-agenda-view {
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
    overflow: auto;
  }
  .rbc-agenda-view table.rbc-agenda-table {
    width: 100%;
    border: 1px solid ${colors.calendar.lightGreyBorder};
    border-spacing: 0;
    border-collapse: collapse;
  }
  .rbc-agenda-view table.rbc-agenda-table tbody > tr > td {
    padding: 5px 10px;
    vertical-align: top;
  }
  .rbc-agenda-view table.rbc-agenda-table .rbc-agenda-time-cell {
    padding-left: 15px;
    padding-right: 15px;
    text-transform: lowercase;
  }
  .rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
    border-left: 1px solid ${colors.calendar.lightGreyBorder};
  }
  .rbc-rtl .rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
    border-left-width: 0;
    border-right: 1px solid ${colors.calendar.lightGreyBorder};
  }
  .rbc-agenda-view table.rbc-agenda-table tbody > tr + tr {
    border-top: 1px solid ${colors.calendar.lightGreyBorder};
  }
  .rbc-agenda-view table.rbc-agenda-table thead > tr > th {
    padding: 3px 5px;
    text-align: left;
    border-bottom: 1px solid ${colors.calendar.lightGreyBorder};
  }
  .rbc-rtl .rbc-agenda-view table.rbc-agenda-table thead > tr > th {
    text-align: right;
  }

  .rbc-agenda-time-cell {
    text-transform: lowercase;
  }
  .rbc-agenda-time-cell .rbc-continues-after:after {
    content: ' »';
  }
  .rbc-agenda-time-cell .rbc-continues-prior:before {
    content: '« ';
  }

  .rbc-agenda-date-cell,
  .rbc-agenda-time-cell {
    white-space: nowrap;
  }

  .rbc-agenda-event-cell {
    width: 100%;
  }

  .rbc-time-column {
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }
  .rbc-time-column .rbc-timeslot-group {
    flex: 1;
  }

  .rbc-timeslot-group {
    border-bottom: 1px solid ${colors.calendar.lightGreyBorder};
    min-height: 40px;
    display: flex;
    flex-flow: column nowrap;
  }

  .rbc-time-gutter,
  .rbc-header-gutter {
    flex: none;
  }

  .rbc-label {
    padding: 0 5px;
  }

  .rbc-day-slot {
    position: relative;
  }
  .rbc-day-slot .rbc-events-container {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    margin-right: 10px;
    top: 0;
  }
  .rbc-day-slot .rbc-events-container.rbc-rtl {
    left: 10px;
    right: 0;
  }
  .rbc-day-slot .rbc-event {
    display: flex;
    max-height: 100%;
    min-height: 20px;
    flex-flow: column wrap;
    align-items: flex-start;
    overflow: hidden;
    position: absolute;
  }
  .rbc-day-slot .rbc-event-label {
    flex: none;
    padding-right: 5px;
    width: auto;
  }
  .rbc-day-slot .rbc-event-content {
    width: 100%;
    flex: 1 1 0;
    font-size: ${pxToRem(16)};
    word-wrap: break-word;
    line-height: 1;
    height: 100%;
    min-height: 1em;
  }
  .rbc-day-slot .rbc-time-slot {
    border-top: 1px solid #f7f7f7;
  }

  .rbc-time-view-resources .rbc-time-gutter,
  .rbc-time-view-resources .rbc-time-header-gutter {
    position: sticky;
    left: 0;
    background-color: white;
    border-right: 1px solid ${colors.calendar.lightGreyBorder};
    z-index: 10;
    margin-right: -1px;
  }

  .rbc-time-view-resources .rbc-time-header {
    overflow: hidden;
  }

  .rbc-time-view-resources .rbc-time-header-content {
    min-width: auto;
    flex: 1 0 0;
    flex-basis: 0px;
  }

  .rbc-time-view-resources .rbc-time-header-cell-single-day {
    display: none;
  }

  .rbc-time-view-resources .rbc-day-slot {
    min-width: 140px;
  }

  .rbc-time-view-resources .rbc-header,
  .rbc-time-view-resources .rbc-day-bg {
    width: 140px;
    flex: 1 1 0;
    flex-basis: 0 px;
  }

  .rbc-time-header-content + .rbc-time-header-content {
    margin-left: -1px;
  }

  .rbc-time-slot {
    flex: 1 0 0;
  }
  .rbc-time-slot.rbc-now {
    font-weight: bold;
  }

  .rbc-day-header {
    text-align: center;
  }

  .rbc-slot-selection {
    z-index: 10;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    font-size: 75%;
    width: 100%;
    padding: 3px;
  }

  .rbc-slot-selecting {
    cursor: move;
  }

  .rbc-time-view {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    min-height: 0;
  }
  .rbc-time-view .rbc-time-gutter {
    white-space: nowrap;
  }
  .rbc-time-view .rbc-allday-cell {
    box-sizing: content-box;
    width: 100%;
    height: 100%;
    padding-top: ${spacing(0.5)};
    position: relative;
  }
  .rbc-time-view .rbc-allday-cell + .rbc-allday-cell {
    border-left: 1px solid ${colors.calendar.lightGreyBorder};
  }
  .rbc-time-view .rbc-allday-events {
    position: relative;
    z-index: 4;
  }
  .rbc-time-view .rbc-row {
    box-sizing: border-box;
    min-height: 20px;
  }

  .rbc-time-header {
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
  }
  .rbc-time-header.rbc-overflowing {
    border-right: 1px solid ${colors.calendar.lightGreyBorder};
  }
  .rbc-rtl .rbc-time-header.rbc-overflowing {
    border-right-width: 0;
    border-left: 1px solid ${colors.calendar.lightGreyBorder};
  }
  .rbc-time-header > .rbc-row:first-of-type {
    border-bottom: 1px solid ${colors.calendar.lightGreyBorder};
  }
  .rbc-time-header > .rbc-row.rbc-row-resource {
    border-bottom: 1px solid ${colors.calendar.lightGreyBorder};
  }

  .rbc-time-header-cell-single-day {
    display: none;
  }

  .rbc-time-header-content {
    flex: 1;
    display: flex;
    min-width: 0;
    flex-direction: column;

    .rbc-allday-cell {
      .rbc-day-bg {
        border-left: 1px solid ${colors.calendar.lightGreyBorder};
      }
    }
  }
  .rbc-rtl .rbc-time-header-content {
    border-left-width: 0;
    border-right: 1px solid ${colors.calendar.lightGreyBorder};
  }
  .rbc-time-header-content > .rbc-row.rbc-row-resource {
    border-bottom: 1px solid ${colors.calendar.lightGreyBorder};
    flex-shrink: 0;
  }

  .rbc-time-content {
    display: flex;
    flex: 1 0 0%;
    align-items: flex-start;
    width: 100%;
    border-top: 2px solid ${colors.calendar.lightGreyBorder};
    overflow-y: auto;
    position: relative;

    /* .rbc-event-content {
      visibility: hidden;
    } */
  }
  .rbc-time-content > .rbc-time-gutter {
    flex: none;
  }
  .rbc-time-content > * + * > * {
    border-left: 1px solid ${colors.calendar.lightGreyBorder};
  }
  .rbc-rtl .rbc-time-content > * + * > * {
    border-left-width: 0;
    border-right: 1px solid ${colors.calendar.lightGreyBorder};
  }
  .rbc-time-content > .rbc-day-slot {
    width: 100%;
    user-select: none;
  }

  .rbc-current-time-indicator {
    position: absolute;
    z-index: 3;
    left: 0;
    right: 0;
    height: 1px;
    background-color: ${colors.secondary as string};
    pointer-events: none;
  }
`;

export const ResourceCalendarView = styled.div<{ columnWidth?: string }>`
  .rbc-allday-cell {
    box-sizing: border-box;
    box-shadow: 0.1px 0.1px 2px 0.1px #cbcaca;
    border-right: 1px solid #ddd;
    border-left: 1px solid #ddd;
    width: ${(props) => props.columnWidth || '230px'};
    min-width: ${(props) => props.columnWidth || '230px'};
    max-width: ${(props) => props.columnWidth || '230px'};
  }

  .rbc-time-view .rbc-header {
    border-bottom: none;
  }
  .rbc-time-header {
    gap: 10.7px;
  }

  .rbc-time-header-content {
    border-left: none;
    width: ${(props) => props.columnWidth || '230px'};
    min-width: ${(props) => props.columnWidth || '230px'};
    max-width: ${(props) => props.columnWidth || '230px'};
  }

  .rbc-time-content > * + * > * {
    border-left: none;
    border-right: none;
    width: ${(props) => props.columnWidth || '230px'};
  }

  .rbc-time-content {
    gap: 10px;
    border-top: none;
  }

  .rbc-day-slot .rbc-events-container {
    margin-right: 0px;
  }

  .rbc-header {
    padding: 0px;
    width: ${(props) => props.columnWidth || '230px'};
    min-width: ${(props) => props.columnWidth || '230px'};
    max-width: ${(props) => props.columnWidth || '230px'};
  }

  .rbc-time-header-content > .rbc-row.rbc-row-resource {
    border-bottom: none;
    width: ${(props) => props.columnWidth || '230px'};
    min-width: ${(props) => props.columnWidth || '230px'};
    max-width: ${(props) => props.columnWidth || '230px'};
  }

  .rbc-time-view .rbc-time-gutter {
    border-right: none;
  }

  .rbc-time-header-gutter {
    border-right: none;
  }

  .rbc-label {
    font-family: 'Open Sans';
    font-size: 12px;
  }

  .rbc-events-container {
    width: ${(props) => props.columnWidth || '230px'};
  }

  .rbc-time-content > .rbc-day-slot {
    box-sizing: border-box;
    box-shadow: 0.1px 0.1px 2px 0.1px #cbcaca;
    border-right: 1px solid #ddd;
    border-left: 1px solid #ddd;
    width: ${(props) => props.columnWidth || '230px'};
    min-width: ${(props) => props.columnWidth || '230px'};
    max-width: ${(props) => props.columnWidth || '230px'};
  }
`;
