import React, { FC } from 'react';
import { AppointmentStatus, EventType } from './Calendar.interface';
import { Div } from 'components/Div';
import { EventProps } from 'react-big-calendar';
import { CancelRounded, CheckCircleRounded, Info, MonetizationOnRounded } from '@material-ui/icons';
import { Typography } from 'components/Typography';
import styled from '@emotion/styled';
import ntucHealth from 'config/theme/ntucHealth';
import Tooltip from '@material-ui/core/Tooltip';
import { SvgIconTypeMap } from '@material-ui/core';
import GroupIcon from 'data/assets/group.svg';
import ScheduleIcon from 'data/assets/schedule.svg';
import { Image } from 'components/Image';

const StyledInfo = styled(Info)`
  color: grey;
`;

const StyledGroupText = styled(Typography)`
  text-decoration: underline;
  padding-right: 0.5rem;
`;

const StyledAppointmentEventType = styled(Typography)`
  padding-right: 3px;
`;

const IconContainer = styled(Div)`
  position: relative;
`;

const StyledToolTipContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const EventTypeMap = new Map<EventType['eventType'], string>([
  ['INITIAL_ASSESSMENT', 'IA'],
  ['RE_ASSESSMENT', 'RA'],
  ['TELE_REHAB', 'TRD'],
  ['UNAVAILABLE', 'Slot Not Available'],
  ['OTHER', '']
]);

function renderStatusIcon(status: AppointmentStatus, _size?: SvgIconTypeMap['props']['fontSize']) {
  const size = _size || 'medium';
  switch (status) {
    case 'COMPLETED':
      return <CheckCircleRounded fontSize={size} color="inherit" />;
    case 'SCHEDULED':
      return <Image src={ScheduleIcon} />;
    case 'CANCELLED':
      return <CancelRounded fontSize={size} color="inherit" />;
    case 'CANCELLED_WITH_CHARGE':
      return (
        <IconContainer>
          <CancelRounded color="error" style={{ fontSize: '9px', position: 'absolute', left: '1px', top: '2px' }} />
          <MonetizationOnRounded fontSize={size} color="inherit" />
        </IconContainer>
      );
    default:
      return <></>;
  }
}

function renderAppointmentEventTypeColor(type?: EventType['eventType']) {
  return type == 'TELE_REHAB'
    ? ntucHealth.white
    : type == 'UNAVAILABLE'
    ? ntucHealth.charcoal900
    : ntucHealth.yellowOrange;
}

function GroupToolTipContent(clients: EventType['enrollments']) {
  if (!clients) {
    return <></>;
  }
  return (
    <StyledToolTipContainer>
      {clients.map((val) => {
        return (
          <Div display="flex" flexDirection="row" alignItems="center" key={val.clientId}>
            {renderStatusIcon(val.status, 'small')}
            <Typography color="white" style={{ paddingLeft: '2px' }} variant="body2">
              {val.clientName}
            </Typography>
          </Div>
        );
      })}
    </StyledToolTipContainer>
  );
}

const ResourceEvent: FC<EventProps<EventType>> = (props) => {
  if (props.event.enrollments && props.event.enrollments.length > 0) {
    return (
      <Div display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        <Div display="flex" flexDirection="row" alignItems="center">
          {props.event?.eventType !== 'UNAVAILABLE' ? (
            props.event.enrollments.length > 1 ? (
              <>
                <StyledGroupText
                  style={{ color: 'rgb(67, 157, 247)' }}
                  variant="body2"
                >{`${props.event.enrollments.length} Clients`}</StyledGroupText>
                <Tooltip title={GroupToolTipContent(props.event.enrollments)}>
                  <span>
                    <Image src={GroupIcon} />
                  </span>
                </Tooltip>
              </>
            ) : (
              <>
                {renderStatusIcon(props.event.enrollments[0].status)}
                <Typography variant="body2" style={{ paddingLeft: '0.2rem', color: 'white' }}>
                  {props.event.enrollments[0].clientName}
                </Typography>
              </>
            )
          ) : (
            <></>
          )}
        </Div>
        <Div display="flex" flexDirection="row" alignItems="center">
          <StyledAppointmentEventType
            variant="caption"
            style={{ color: renderAppointmentEventTypeColor(props.event.eventType), fontWeight: 'bold' }}
          >
            {props.event.eventType && EventTypeMap.get(props.event.eventType)}
          </StyledAppointmentEventType>
          {props.event?.remarks && (
            <Tooltip title={props.event.remarks}>
              {props.event.eventType === 'UNAVAILABLE' || props.event.enrollments.length > 1 ? (
                <StyledInfo fontSize="small" />
              ) : (
                <Info fontSize="small" />
              )}
            </Tooltip>
          )}
        </Div>
      </Div>
    );
  }

  return <></>;
};

export default ResourceEvent;
