import { useEffect, useState } from 'react';

export const useGetAlphaOnScroll = (end: number, start = 0): number => {
  const [alpha, setAlpha] = useState(0);

  const interval = (end - start) / 10;

  useEffect(() => {
    const handleScroll = () => {
      switch (true) {
        case window.scrollY <= start:
          setAlpha(0);
          break;
        case window.scrollY <= start + interval:
          setAlpha(0.1);
          break;
        case window.scrollY <= start + interval * 2:
          setAlpha(0.2);
          break;
        case window.scrollY <= start + interval * 3:
          setAlpha(0.3);
          break;
        case window.scrollY <= start + interval * 4:
          setAlpha(0.4);
          break;
        case window.scrollY <= start + interval * 5:
          setAlpha(0.5);
          break;
        case window.scrollY <= start + interval * 6:
          setAlpha(0.6);
          break;
        case window.scrollY <= start + interval * 7:
          setAlpha(0.7);
          break;
        case window.scrollY <= start + interval * 8:
          setAlpha(0.8);
          break;
        case window.scrollY <= start + interval * 9:
          setAlpha(0.9);
          break;

        default:
          setAlpha(1);
          break;
      }
    };

    document.addEventListener('scroll', handleScroll);

    return () => document.removeEventListener('scroll', handleScroll);
  }, [end, start]);

  return alpha;
};
