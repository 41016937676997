import type { Theme } from '@material-ui/core/styles/createTheme';
import { DivProps } from './Div.interface';
import { makeStyles } from '@material-ui/core/styles';

// HELPERS
import { getColorByColorCode } from 'helpers';

const useClasses = makeStyles<Theme, DivProps, string>(() => ({
  nh_customDivWrapper: {
    border: (props: DivProps) => `${props.borderWidth?.all}px solid ${getColorByColorCode(props.borderColor?.all)}`,
    borderRadius: (props: DivProps) => `${props.borderRadius?.all}px`,
    borderRight: (props: DivProps) =>
      `${props.borderWidth?.right}px solid ${getColorByColorCode(props.borderColor?.right)}`,
    borderLeft: (props: DivProps) =>
      `${props.borderWidth?.left}px solid ${getColorByColorCode(props.borderColor?.left)}`,
    borderTop: (props: DivProps) => `${props.borderWidth?.top}px solid ${getColorByColorCode(props.borderColor?.top)}`,
    borderBottom: (props: DivProps) =>
      `${props.borderWidth?.bottom}px solid ${getColorByColorCode(props.borderColor?.bottom)}`,
    borderTopRightRadius: (props: DivProps) => `${props.borderRadius?.topRight}px`,
    borderTopLeftRadius: (props: DivProps) => `${props.borderRadius?.topLeft}px`,
    borderBottomRightRadius: (props: DivProps) => `${props.borderRadius?.bottomRight}px`,
    borderBottomLeftRadius: (props: DivProps) => `${props.borderRadius?.bottomLeft}px`,
    display: (props: DivProps) => props.display,
    justifyContent: (props: DivProps) => props.justifyContent,
    alignItems: (props: DivProps) => props.alignItems,
    margin: (props: DivProps) => props.margin?.all,
    marginTop: (props: DivProps) => props.margin?.top,
    marginBottom: (props: DivProps) => props.margin?.bottom,
    marginLeft: (props: DivProps) => props.margin?.left,
    marginRight: (props: DivProps) => props.margin?.right,
    boxSizing: 'border-box', // TO PREVENT INCREASING WIDTH FROM CHANGING PADDING
    padding: (props: DivProps) => props.padding?.all,
    paddingTop: (props: DivProps) => props.padding?.top,
    paddingBottom: (props: DivProps) => props.padding?.bottom,
    paddingLeft: (props: DivProps) => props.padding?.left,
    paddingRight: (props: DivProps) => props.padding?.right,
    height: (props: DivProps) => props.height,
    minHeight: (props: DivProps) => props.minHeight,
    minWidth: (props: DivProps) => props.minWidth,
    width: (props: DivProps) => props.width,
    maxWidth: (props: DivProps) => props.maxWidth,
    maxHeight: (props: DivProps) => props.maxHeight,
    backgroundColor: (props: DivProps) =>
      !props.backgroundColor ? 'transparent' : getColorByColorCode(props.backgroundColor),
    overflow: (props: DivProps) => props.overflow,
    overflowX: (props: DivProps) => props.overflowX,
    overflowY: (props: DivProps) => props.overflowY,
    flexDirection: (props: DivProps) => (props.flexDirection ? props.flexDirection : 'column'),
    flex: (props: DivProps) => props.flex,
    opacity: (props: DivProps) => props.alpha,
    zIndex: (props: DivProps) => props.zIndex,
    '&:hover': {
      cursor: (props: DivProps) => {
        if (props.onCLick) {
          return 'pointer';
        }
      }
      // zIndex: 100
    }
  },
  childrenWrapper: {
    minWidth: 0
  }
}));

export default useClasses;
