import type { Theme } from '@material-ui/core/styles/createTheme';
import { FooterProps } from './Footer.interface';
import { makeStyles } from '@material-ui/core/styles';

const useClasses = makeStyles<Theme, Partial<FooterProps>, string>(({ ntucHealth, breakpoints }: Theme) => ({
  footerWrapper: {
    // position: 'fixed',
    // bottom: 0,
    // left: 0,
    width: '100%',
    backgroundColor: ntucHealth.teal700
  },
  flexMainTitle: {
    marginBottom: 19
  },
  flexMainTitleWithHigherMg: {
    [breakpoints.down('md')]: {
      textAlign: 'center',
      marginBottom: 20
    },
    [breakpoints.up('md')]: {
      textAlign: 'end',
      marginBottom: 50
    },
    '&:last-child': {
      marginBottom: 0,
      [breakpoints.down('md')]: {
        textAlign: 'center'
      },
      [breakpoints.up('md')]: {
        textAlign: 'end'
      }
    }
  },
  flexSocialWrapper: {
    [breakpoints.down('md')]: {
      display: 'block',
      textAlign: 'center'
    },
    [breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: 0
    }
  },
  brandWrapper: {
    marginTop: 30
  },
  brand: {
    [breakpoints.down('md')]: {
      textAlign: 'center',
      marginTop: 20
    },
    [breakpoints.up('md')]: {
      textAlign: 'start',
      marginTop: 0
    }
  },
  iconWrapper: {
    color: ntucHealth.white,
    marginTop: 3,
    marginLeft: 5,
    marginRight: 5,
    '&:last-child': {
      marginRight: 0
    },
    '&:hover': {
      cursor: 'pointer'
    }
  }
}));

export default useClasses;
