import React, { FC } from 'react';
import { GradientColor } from 'components/GradientColor';
import { Typography } from 'components/Typography';
import { ContactUsProps } from './ContactUs.interface';
import ContactUsImage from 'data/assets/contact-us.png';
import { Div } from 'components/Div';

const ContactUs: FC<ContactUsProps> = ({
  content = (
    <>
      <Typography color="white" variant="body1">
        Please contact us at
      </Typography>
      <Typography color="white" variant="h1">
        6214 3023
      </Typography>
      <Typography color="white" variant="body2">
        (Monday to Friday, 8.30am to 5.30pm).
      </Typography>
    </>
  ),
  image = ContactUsImage
}) => {
  return (
    <GradientColor
      display="flex"
      flexDirection="row"
      justifyContent="center"
      padding={{ top: 30, bottom: 30, left: 12, right: 12 }}
      width="100%"
    >
      <img style={{ minWidth: '88px', width: '6%' }} src={image} />
      <Div display="flex" justifyContent="space-around" margin={{ left: 25 }}>
        {content}
      </Div>
    </GradientColor>
  );
};

export default ContactUs;
