import React, { FC } from 'react';
import clsx from 'clsx';
import {
  Table as MuiTable,
  TableContainer as MuiTableContainer,
  TableBody as MuiTableBody,
  TableHead as MuiTableHead,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell
} from '@material-ui/core';
import MuiPagination from '@material-ui/lab/Pagination';

// DIV
import { Div } from 'components/Div';

// STYLE
import { useClassesTableHead, useClassesTableBody, useClassesTableCell, useClassesTableRow } from './Table.style';

// INTERFACE
import {
  TableProps,
  TableRowProps,
  TableBodyProps,
  TableCellProps,
  TableHeadProps,
  PaginationProps
} from './Table.interface';

export const Table: FC<TableProps> = ({ stickyHeader, className, children, size, testId }) => {
  return (
    <MuiTableContainer>
      <MuiTable data-testid={testId} stickyHeader={stickyHeader} className={className} size={size}>
        {children}
      </MuiTable>
    </MuiTableContainer>
  );
};
Table.defaultProps = {
  size: 'medium'
};

export const TableHead: FC<TableHeadProps> = ({ children, className: classNameProps, ...rest }) => {
  const classes = useClassesTableHead({ ...rest });
  const className = clsx(classes.nh_customTableHead, classNameProps);

  return (
    <MuiTableHead className={className} {...rest}>
      {children}
    </MuiTableHead>
  );
};

export const TableBody: FC<TableBodyProps> = ({ children, className: classNameProps, ...rest }) => {
  const classes = useClassesTableBody({ ...rest });
  const className = clsx(classes.nh_customTableBody, classNameProps);

  return (
    <MuiTableBody className={className} {...rest}>
      {children}
    </MuiTableBody>
  );
};

export const TableRow: FC<TableRowProps> = ({ children, className: classNameProps, borderRadius, ...rest }) => {
  const classes = useClassesTableRow({ borderRadius, ...rest });
  const className = clsx(classes.nh_customTableRow, classNameProps);

  return (
    <MuiTableRow className={className} {...rest}>
      {children}
    </MuiTableRow>
  );
};
TableRow.defaultProps = {
  spacing: 0,
  borderRadius: 0
};

export const TableCell: FC<TableCellProps> = ({ children, className: classNameProps, ...rest }) => {
  const classes = useClassesTableCell({ ...rest });
  const className = clsx(classes.nh_customTableCell, classNameProps);

  return (
    <MuiTableCell className={className} {...rest}>
      <Div className="table-cell-wrapper">{children}</Div>
    </MuiTableCell>
  );
};

export const Pagination: FC<PaginationProps> = ({ ...rest }) => {
  return <MuiPagination {...rest} />;
};
