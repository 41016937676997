import type { Theme } from '@material-ui/core/styles/createTheme';
import { NavigationProps } from './Navigation.interface';
import { makeStyles } from '@material-ui/core/styles';

const useClasses = makeStyles<Theme, Partial<NavigationProps>, string>(() => ({
  navigationWrapper: {
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  userWrapper: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px !important',
    width: 'unset'
  },
  userWrapperWithWidth: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px !important',
    width: 200
  },
  menuWrapper: {
    '& .MuiPaper-root': {
      top: '80px !important'
    },
    '& .MuiMenu-list': {
      padding: '0px !important'
    }
  },
  icon: {
    '& svg polyline': {
      stroke: '#fff'
    }
  }
}));

export default useClasses;
