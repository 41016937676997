import Div from 'components/Div/Div';
import React, { FC } from 'react';
import { HeroProps } from './Hero.interface';
import useHeroClasses from './Hero.styles';

const Hero: FC<HeroProps> = ({ bgImage, bgSize = 'cover', children, ...props }) => {
  const classes = useHeroClasses({ bgImage, bgSize });

  return (
    <Div {...props} className={classes.root}>
      {children}
    </Div>
  );
};

Hero.defaultProps = {
  bgSize: 'cover'
};

export default Hero;
