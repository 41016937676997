import React, { FC, useMemo } from 'react';
import { AppBar, Popover } from '@material-ui/core';
import { Div } from 'components/Div';
import { CatalogNavigationProps } from './CatalogNavigation.interface';
import { Image } from 'components/Image';
import { CartIcon, NTUCHealthLogoWhite, HeroBg, HeroImg } from 'data';
import useCatalogNaviClasses from './CatalogNavigation.style';
import GradientColor from 'components/GradientColor/GradientColor';
import { ProductType } from 'interface';
import { Typography } from 'components/Typography';
import { Grid } from 'components/Grid';
import { useGetAlphaOnScroll } from 'helpers';
import { Avatar } from 'components/Avatar';
import { Hero } from 'components/Hero';
import { Button } from 'components/Button';
import { ProductDetailCard } from 'components/ProductDetailCard';
import { productCategoriesData } from 'data/navigation';
import { Container } from 'components/Container';
import { isXSScreen, isSmScreen, isMdScreen } from 'hooks/MediaQuery';

const CatalogNavigation: FC<CatalogNavigationProps> = ({
  isAuthenticated,
  onBrandClick,
  user,
  isTransparent,
  onCheckout,
  cartQuantity,
  cartItems = [],
  onLogin,
  showHeroBg,
  onClickCategory,
  onUserClick,
  activeCategory,
  onRemoveItemCart,
  alphaEnd,
  alphaStart,
  showCategoryItems,
  CustomeView
}) => {
  const xsScreen = isXSScreen();
  const smScreen = isSmScreen();
  const mdScreen = isMdScreen();
  const classes = useCatalogNaviClasses({ isTransparent, showHeroBg });
  const serviceType = activeCategory || (window.location.pathname.replace('/service-type/', '') as ProductType);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const calculateAlpha = useMemo(() => {
    if (xsScreen) {
      return {
        start: showHeroBg ? 100 : 100,
        end: showHeroBg ? 300 : 500
      };
    }
    if (smScreen) {
      return {
        start: showHeroBg ? 100 : 100,
        end: showHeroBg ? 500 : 500
      };
    }

    return {
      start: showHeroBg ? 400 : 100,
      end: showHeroBg ? 700 : 500
    };
  }, [xsScreen, smScreen, mdScreen]);

  const alpha = useGetAlphaOnScroll(alphaEnd || calculateAlpha.end, alphaStart || calculateAlpha.start);

  const iconWidth = useMemo(() => {
    if (xsScreen) {
      return 50;
    }
    if (smScreen) {
      return 100;
    }

    if (mdScreen) {
      return 130;
    }

    return 160;
  }, [xsScreen, smScreen, mdScreen]);

  const iconWidthSmall = useMemo(() => {
    if (xsScreen) {
      return 50;
    }
    if (smScreen) {
      return 70;
    }

    if (mdScreen) {
      return 90;
    }

    return 110;
  }, [xsScreen, smScreen, mdScreen]);

  const marginTop = useMemo(() => {
    if (xsScreen) {
      return -30;
    }

    if (smScreen) {
      return -50;
    }

    if (mdScreen) {
      return -60;
    }

    return -80;
  }, [xsScreen, smScreen, mdScreen]);

  const fontSize = useMemo(() => {
    if (xsScreen) {
      return 7;
    }

    if (smScreen) {
      return 12;
    }

    if (mdScreen) {
      return 15;
    }

    return 17;
  }, [xsScreen, smScreen, mdScreen]);

  const heroHeight = useMemo(() => {
    if (xsScreen) {
      return '430px';
    }

    if (smScreen) {
      return '530px';
    }

    return '680px';
  }, [xsScreen, smScreen, mdScreen]);

  const paddingHeader = useMemo(() => {
    if (xsScreen) {
      return {
        left: 15,
        right: 15
      };
    }

    if (smScreen) {
      return {
        left: 40,
        right: 40
      };
    }

    if (mdScreen) {
      return {
        left: 50,
        right: 50
      };
    }

    return {
      left: 150,
      right: 150
    };
  }, [xsScreen, smScreen, mdScreen]);

  const imgSize = useMemo(() => {
    if (xsScreen) {
      return 250;
    }

    if (smScreen) {
      return 450;
    }

    if (mdScreen) {
      return 650;
    }

    return 850;
  }, [xsScreen, smScreen, mdScreen]);

  const fontSizeForHero = useMemo(() => {
    if (xsScreen) {
      return 15;
    }

    if (smScreen) {
      return 15;
    }

    if (mdScreen) {
      return 15;
    }

    return 30;
  }, [xsScreen, smScreen, mdScreen]);

  const buttonSize = useMemo(() => {
    if (xsScreen) {
      return 'small';
    }

    if (smScreen) {
      return 'small';
    }

    if (mdScreen) {
      return 'medium';
    }

    return 'large';
  }, [xsScreen, smScreen, mdScreen]);

  const cartIconSize = useMemo(() => {
    if (xsScreen) {
      return 50;
    }

    if (smScreen && !isAuthenticated) {
      return 60;
    }

    if (smScreen && isAuthenticated) {
      return 50;
    }

    if (mdScreen && !isAuthenticated) {
      return 60;
    }

    if (mdScreen && isAuthenticated) {
      return 50;
    }

    if (!isAuthenticated) {
      return 70;
    }

    return 60;
  }, [xsScreen, smScreen, mdScreen, isAuthenticated]);

  const marginTopHeroImg = useMemo(() => {
    if (xsScreen) {
      return 150;
    }

    if (smScreen) {
      return 100;
    }

    return 0;
  }, [xsScreen, smScreen, mdScreen]);

  const textImg = useMemo(() => {
    if (xsScreen) {
      return (
        <>
          <Typography style={{ fontSize: 10, width: imgSize, marginTop: 5 }} align="center" fontWeight="semibold">
            Comprehensive and affordable care services
          </Typography>
          <Typography style={{ fontSize: 10, width: imgSize }} align="center" fontWeight="semibold">
            for the whole family
          </Typography>
        </>
      );
    }

    return (
      <>
        <Typography
          style={{ fontSize: fontSizeForHero, lineHeight: 'normal', marginTop: 5, width: imgSize }}
          color="primary"
          align="center"
          fontWeight="semibold"
        >
          {'Comprehensive and affordable care services'}
        </Typography>
        <Typography
          style={{ fontSize: fontSizeForHero, lineHeight: 'normal', width: imgSize }}
          color="primary"
          align="center"
          fontWeight="semibold"
        >
          for the whole family
        </Typography>
      </>
    );
  }, [imgSize, xsScreen, smScreen, mdScreen]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const mainNavContent = (
    <>
      <Div display="flex" justifyContent="center" onCLick={onBrandClick}>
        <Image src={NTUCHealthLogoWhite} />
      </Div>

      <Grid className={classes.navigationWrapper} container>
        {!isAuthenticated && (
          <Grid item>
            <Button onClick={onLogin} variant="primary" size={buttonSize} className={classes.naviBtn}>
              Login
            </Button>
          </Grid>
        )}

        {isAuthenticated && user && (
          <Grid item>
            <Div display="flex" flexDirection="row" alignItems="center" onCLick={onUserClick && onUserClick}>
              <Div margin={{ right: 10 }}>
                <Typography bold color="white" variant="body2" align="center">
                  {user.fullName.toUpperCase()}
                </Typography>
              </Div>
              <Avatar url={user.avatar} size="sm" />
            </Div>
          </Grid>
        )}

        <Grid item>
          <Button
            aria-controls="user-menu"
            aria-haspopup="true"
            disableRipple
            fullHeight
            withoutBorder
            variant="secondary"
            onClick={handleClick}
            className={classes.cartWrapper}
          >
            <Image src={CartIcon} width={cartIconSize} />
            {cartQuantity && cartQuantity > 0 ? (
              <Div display="flex" alignItems="center" justifyContent="center" className={classes.cartQuantity}>
                <Typography color="white">{cartQuantity}</Typography>
              </Div>
            ) : null}
          </Button>
          <Popover
            id="user-menu"
            anchorEl={anchorEl}
            elevation={0}
            getContentAnchorEl={null}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
            classes={{ paper: classes.popover }}
          >
            {!!cartQuantity && cartQuantity > 0 && cartItems.length > 0 ? (
              <Grid container className={classes.cartItemsWrapper} spacing={2}>
                {cartItems.map((c, i) => (
                  <Grid key={i} item xs={12}>
                    <ProductDetailCard
                      title={c.title}
                      content={<Div>{c.content}</Div>}
                      footer={
                        <Button fullWidth variant="secondary" onClick={() => onRemoveItemCart && onRemoveItemCart(i)}>
                          Remove
                        </Button>
                      }
                      isInCart
                    />
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Typography color="white">Your cart is empty</Typography>
            )}
            {!!cartQuantity && cartQuantity > 0 && cartItems.length > 0 && (
              <Button size="large" onClick={onCheckout} fullWidth className={classes.checkoutBtn}>
                Proceed check out
              </Button>
            )}
          </Popover>
        </Grid>
        {isAuthenticated && user && CustomeView && <Grid item>{CustomeView}</Grid>}
      </Grid>
    </>
  );

  return (
    <>
      <AppBar
        className={classes.root}
        position="sticky"
        style={{ boxShadow: alpha === 1 ? 'rgba(0, 0, 0, 0.35) 0px 5px 15px' : 'unset' }}
      >
        <Div overflow="hidden" className={classes.wrapper}>
          <GradientColor
            padding={paddingHeader}
            minHeight={80}
            display="flex"
            flexDirection="row"
            isTransparent={isTransparent}
            alpha={alpha}
          >
            <Container isSmall={false} isSmallProductCatalog>
              <Div display="flex" flexDirection="row" height="100%">
                {mainNavContent}
              </Div>
            </Container>
          </GradientColor>

          {showCategoryItems && (
            <Div alpha={isTransparent ? alpha : 1} className={classes.itemWrapper} shadowLevel={10}>
              <Container isSmall={false}>
                <Div display="flex" flexDirection="row">
                  {productCategoriesData.map((v) => {
                    return (
                      <Div
                        key={v.id}
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        flex={1}
                        onCLick={() => {
                          if (v.type === 'CommunitySupport' || v.type === 'HomeCare' || v.type === 'DayCare') return;

                          if (onClickCategory) {
                            onClickCategory(v.type === serviceType ? undefined : v.type);
                          }
                        }}
                      >
                        <img
                          src={v.img}
                          style={{
                            maxWidth: iconWidthSmall,
                            height: iconWidthSmall
                          }}
                        />
                        <Typography
                          color={v.type === serviceType ? 'secondary' : 'primary'}
                          align="center"
                          bold
                          style={{
                            marginTop: 5,
                            marginBottom: 15,
                            fontSize: fontSize,
                            opacity: v.type === 'ActiveAgeing' || v.type === 'DentiCare' ? 1 : 0.5
                          }}
                        >
                          {v.label}
                        </Typography>
                      </Div>
                    );
                  })}
                </Div>
              </Container>
            </Div>
          )}
        </Div>
      </AppBar>
      {showHeroBg && (
        <Hero
          display="flex"
          justifyContent="center"
          alignItems="center"
          height={heroHeight}
          margin={{ top: -300 }}
          bgImage={HeroBg}
        >
          <img src={HeroImg} style={{ width: imgSize, marginTop: marginTopHeroImg }} />
          {textImg}
        </Hero>
      )}
      {showCategoryItems && (
        <Div margin={{ top: marginTop }} zIndex={showHeroBg ? 0 : 10000}>
          <Container isSmall={false} isProductCatalog>
            <Div display="flex" flexDirection="row">
              {productCategoriesData.map((v) => {
                return (
                  <Div
                    key={v.id}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    flex={1}
                    onCLick={() => {
                      if (v.type === 'CommunitySupport' || v.type === 'HomeCare' || v.type === 'DayCare') return;

                      if (onClickCategory) {
                        onClickCategory(v.type === serviceType ? undefined : v.type);
                      }
                    }}
                  >
                    <img
                      src={v.type === serviceType ? v.activeImg : v.img}
                      style={{
                        maxWidth: iconWidth,
                        height: iconWidth
                      }}
                    />
                    <Typography
                      color={v.type === serviceType ? 'secondary' : 'primary'}
                      align="center"
                      bold
                      style={{
                        marginTop: 10,
                        marginBottom: 25,
                        fontSize: fontSize,
                        opacity: v.type === 'ActiveAgeing' || v.type === 'DentiCare' ? 1 : 0.5
                      }}
                    >
                      {v.label}
                    </Typography>
                  </Div>
                );
              })}
            </Div>
          </Container>
        </Div>
      )}
    </>
  );
};

export default CatalogNavigation;
