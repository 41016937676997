import React, { FC } from 'react';
import { Div } from 'components/Div';
import { GradientColorProps } from './GradientColor.interface';
import useClasses from './GradientColor.styles';

const GradientColor: FC<GradientColorProps> = ({ children, isTransparent = false, colors, alpha, ...rest }) => {
  const classes = useClasses({ colors, isTransparent, alpha });

  if (isTransparent) {
    return (
      <Div className={classes.transparent} {...rest}>
        {children}
      </Div>
    );
  }

  return (
    <Div className={classes.gradient} {...rest}>
      {children}
    </Div>
  );
};

GradientColor.defaultProps = {
  colors: ['secondary', 'teal']
};

export default GradientColor;
