import React, { FC } from 'react';
import MuiAlert from '@material-ui/lab/Alert';

// INTERFACE
import { AlertProps } from './Alert.interface';

// //STYLE
import useClasses from './Alert.styles';

const Alert: FC<AlertProps> = ({ message, variant, open, onClose, isOutlined, showIcon, testId }) => {
  const className = useClasses({ variant });

  if (!open) return null;

  if (isOutlined) {
    return (
      <MuiAlert
        data-testid={testId}
        icon={showIcon}
        severity={variant === 'support' ? 'info' : variant}
        onClose={onClose}
        elevation={6}
        variant="outlined"
      >
        {message}
      </MuiAlert>
    );
  }

  return (
    <MuiAlert
      data-testid={testId}
      icon={showIcon}
      severity={variant === 'support' ? 'info' : variant}
      onClose={onClose}
      elevation={6}
      variant="filled"
      className={className.nh_customAlert}
    >
      {message}
    </MuiAlert>
  );
};

export default Alert;
