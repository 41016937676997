import type { Theme } from '@material-ui/core/styles/createTheme';
import { makeStyles } from '@material-ui/core/styles';
import { HeroProps } from './Hero.interface';

const useHeroClasses = makeStyles<Theme, HeroProps, string>(() => ({
  root: {
    backgroundImage: (props: HeroProps) => `url("${props.bgImage}")`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: (props: HeroProps) => props.bgSize
  }
}));

export default useHeroClasses;
