import React, { FC, useState } from 'react';
import { Grid, AppBar } from '@material-ui/core';
import { GrDown, GrUp } from 'react-icons/gr';

// INTERFACE
import { NavigationProps } from './Navigation.interface';

// // STYLE
import useClasses from './Navigation.style';

// COMPONENT
import Typography from 'components/Typography/Typo';
import { Div } from 'components/Div';
import { Image } from 'components/Image';
import { Button } from 'components/Button';
import { Collapse } from 'components/Collapse';
import { Avatar } from 'components/Avatar';
import { GradientColor } from 'components/GradientColor';

// MODULE
import { NavigationItemDropDown, UserRow, ManageUserProfile, CollapseMenuItem } from './Navigation.module';

// DEFAULT DATA
import { Menu, NTUCHealthLogoWhite } from 'data';

const MobileNavigation: FC<NavigationProps> = ({
  labels = [],
  user,
  userMenu = [],
  isAuthenticated,
  onLogin,
  onLogout,
  notifications,
  onSelectProfile,
  onBrandClick,
  CustomeView
}) => {
  const [openMenu, setOpenMenu] = useState(false);
  const [openCollapseUserMenu, setOpenCollapseUserMenu] = useState(false);
  const baseClasses = useClasses({});

  const handleClickHeader = () => {
    if (isAuthenticated && onLogout) return onLogout();

    if (onLogin) {
      return onLogin();
    }
  };

  return (
    <>
      <AppBar position="sticky">
        <GradientColor
          colors={['teal']}
          padding={{ all: 10 }}
          overflowY="hidden"
          overflowX="hidden"
          minHeight={90}
          display="flex"
          flexDirection="row"
        >
          <Div display="flex" justifyContent="center" padding={{ left: 10 }} onCLick={onBrandClick}>
            <Image src={NTUCHealthLogoWhite} />
          </Div>
          <Grid className={baseClasses.navigationWrapper} container spacing={2}>
            {CustomeView && <Grid item>{CustomeView}</Grid>}

            <Grid item>
              <Typography onClick={handleClickHeader} hoverColor="secondary" bold color="white" variant="body2">
                {isAuthenticated ? 'LOG OUT' : 'LOGIN'}
              </Typography>
            </Grid>
            <Grid item>
              <Button disableRipple variant="secondary" withoutBorder onClick={() => setOpenMenu((pre) => !pre)}>
                <Image src={Menu} />
              </Button>
            </Grid>
          </Grid>
        </GradientColor>
      </AppBar>
      <Collapse isOpen={openMenu}>
        {isAuthenticated && user && (
          <>
            <Div
              backgroundColor="grey"
              height={50}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              flexDirection="row"
              padding={{ all: 10 }}
              onCLick={() => setOpenCollapseUserMenu((pre) => !pre)}
            >
              <Div display="flex" flexDirection="row" alignItems="center">
                <Avatar url={user.avatar} />
                <Div margin={{ left: 10 }}>
                  <Typography bold color="primary" variant="body2" align="center">
                    {user.fullName.toUpperCase()}
                  </Typography>
                </Div>
              </Div>
              <Div padding={{ right: 25 }}>{openCollapseUserMenu ? <GrUp /> : <GrDown />}</Div>
            </Div>

            <Collapse isOpen={openCollapseUserMenu}>
              {userMenu.map((v, i) => {
                if (v.label === 'NOTIFICATIONS') {
                  return (
                    <NavigationItemDropDown
                      key={i}
                      label={v.label}
                      onClick={v.onClick}
                      notifications={notifications}
                      color="ice"
                    />
                  );
                }

                if (v.subItems && v.subItems.length > 0) {
                  return <CollapseMenuItem key={i} label={v.label} subItems={v.subItems} color="ice" />;
                }

                return <NavigationItemDropDown key={i} label={v.label} onClick={v.onClick} color="ice" />;
              })}
              {user.userMembers && user.userMembers.length > 0 && (
                <Div
                  backgroundColor="ice"
                  height={50}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  flexDirection="row"
                  padding={{ all: 10 }}
                >
                  <Typography color="primary" variant="body2" align="center">
                    View profile of
                  </Typography>
                </Div>
              )}
              {user.userMembers &&
                user.userMembers.map((v, i) => {
                  return <UserRow key={`${v.id} - ${i}`} user={v} onSelectProfile={onSelectProfile} color="ice" />;
                })}
              {user.userMembers && user.userMembers.length > 0 ? (
                <ManageUserProfile onSelectProfile={onSelectProfile} color="ice" />
              ) : null}
            </Collapse>
          </>
        )}

        {labels.map((v, i) => {
          return <NavigationItemDropDown key={i} label={v.label} onClick={v.onClick} withDownIcon />;
        })}
      </Collapse>
    </>
  );
};

export default MobileNavigation;
