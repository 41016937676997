import type { Theme } from '@material-ui/core/styles/createTheme';
import { CheckBoxProps } from './CheckBox.interface';
import { makeStyles } from '@material-ui/core/styles';

const useClasses = makeStyles<Theme, CheckBoxProps, string>(({ ntucHealth }) => ({
  nh_customCheckBox: {
    '&.MuiCheckbox-root': {
      color: ntucHealth.teal700
    }
  }
}));

export default useClasses;
