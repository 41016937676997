import type { Theme } from '@material-ui/core/styles/createTheme';
import { LoginProps } from './Login.interface';
import { makeStyles } from '@material-ui/core/styles';

const useClasses = makeStyles<Theme, Partial<LoginProps>, string>(({ ntucHealth }) => ({
  lineWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    height: 50
  },
  ORWrapper: {
    position: 'absolute',
    backgroundColor: ntucHealth.white,
    paddingLeft: 5,
    paddingRight: 5
  }
}));

export default useClasses;
