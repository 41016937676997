import type { Theme } from '@material-ui/core/styles/createTheme';
import { PackageDetailsTableProps } from './PackageDetailsTable.interface';
import { makeStyles } from '@material-ui/core/styles';

const useClasses = makeStyles<Theme, PackageDetailsTableProps, string>(({ ntucHealth }) => {
  return {
    nh_packeDeitalsTableWrapper: {
      backgroundColor: ntucHealth.white,
      border: '1px solid #E2E2E2',
      boxSizing: 'border-box',
      borderRadius: 4,
      boxShadow: '0px 2px 2px rgba(152, 162, 179, 0.15), 0px 1px 5px rgba(152, 162, 179, 0.15)',

      '& .nh-custom-package-table-container': {
        minWidth: 500
      },

      '& .title': {
        margin: 16
      },

      '& .MuiTableContainer-root': {
        paddingBottom: 20,
        borderBottom: '1px solid #E2E2E2'
      },

      '& .MuiTableCell-head': {
        borderBottom: '1px solid #E2E2E2',
        borderTop: '1px solid #E2E2E2',
        fontWeight: 600,
        color: ntucHealth.charcoal700,
        fontSize: 15
      },

      '& .MuiTableCell-body': {
        fontWeight: 'normal',
        color: ntucHealth.charcoal700,
        fontSize: 15,
        paddingBottom: 0
      }
    },

    nhStickyColumn: {
      width: window.innerWidth > 500 ? 'unset' : 120,
      position: 'sticky',
      left: 0,
      background: 'white'
    },

    discountRow: {
      '& .MuiTableCell-root': {
        paddingTop: 5,
        paddingBottom: 10
      }
    },

    nhRemoveIcon: {
      color: ntucHealth.red900
    }
  };
});

export default useClasses;
