import {
  Table as BaseTable,
  TableBody as BaseTableBody,
  TableHead as BaseTableHead,
  TableRow as BaseTableRow,
  TableCell as BaseTableCell,
  Pagination as BasePagination
} from './Table';

const Table = BaseTable;
const TableBody = BaseTableBody;
const TableHead = BaseTableHead;
const TableRow = BaseTableRow;
const TableCell = BaseTableCell;
const Pagination = BasePagination;

export { Table, TableBody, TableHead, TableRow, TableCell, Pagination };
export type {
  TableProps,
  TableHeadProps,
  TableBodyProps,
  TableRowProps,
  TableCellProps,
  PaginationProps
} from './Table.interface';
