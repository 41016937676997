import React, { FC, ReactNode } from 'react';
import { EventType } from './Calendar.interface';
import { EventWrapperProps } from 'react-big-calendar';
import { statusColorMap } from './Calendar.style';
import ntucHealth from 'config/theme/ntucHealth';
import Tooltip from '@material-ui/core/Tooltip';

const ResourceEventWrapper: FC<
  // eslint-disable-next-line no-unused-vars
  EventWrapperProps<EventType> & { onClick: (id: string) => void; children?: ReactNode }
> = (props) => {
  function getStatusStyle() {
    if (props.event.type === 'PUBLIC_HOLIDAY') {
      return { backgroundColor: ntucHealth.calendar.publicHolidays };
    }

    if (props.event.type === 'LEAVE') {
      return {
        background: `repeating-linear-gradient(
        135deg,
        transparent,
        transparent 3px,
        ${ntucHealth.charcoal300} 3px,
        ${ntucHealth.charcoal300} 18px
      )
      `,
        border: 'none'
      };
    }

    if (props.event.eventType === 'UNAVAILABLE') {
      return { backgroundColor: ntucHealth.charcoal300 };
    }

    if (props.event.enrollments) {
      if (props.event.enrollments?.length > 1) {
        return { backgroundColor: ntucHealth.charcoal100 };
      }

      if (props.event.enrollments?.length > 0) {
        return { backgroundColor: statusColorMap[props.event.enrollments[0].status], border: 'none' };
      }
    }

    return { backgroundColor: ntucHealth.charcoal300 };
  }

  const children = { ...(props.children as any) };
  children.props = { ...children.props };
  children.props.style = { ...children.props.style, borderRadius: '0px', ...getStatusStyle() };
  children.props.children = children.props.children.length > 0 ? children.props.children[1] : children.props.children;
  if (props.event.allDay) {
    return (
      <div onClick={() => props.onClick(props.event.id)} style={{ height: '100%', display: 'flex' }}>
        {children}
      </div>
    );
  }
  if (props.event.type === 'LEAVE') {
    return (
      <div style={{ height: '100%', display: 'flex' }}>
        <Tooltip title={(props.event?.group as any).replaceAll('_', ' ') || ''}>{children}</Tooltip>
      </div>
    );
  }
  return <div onClick={() => props.onClick(props.event.id)}>{children}</div>;
};

export default ResourceEventWrapper;
