import { DatePicker as BaseDatePicker } from './DatePicker';
import { DatePickerStatic as BaseDatePickerStatic } from './DatePicker.static';

// EXPORT
export const DatePicker = BaseDatePicker;
export const DatePickerStatic = BaseDatePickerStatic;
export type {
  TimePickerProps,
  DatePickerProps,
  DateTimePickerProps,
  DatePickerStaticProps
} from './DatePicker.interface';
