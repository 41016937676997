import React, { FC } from 'react';
import MuiCheckbox from '@material-ui/core/Checkbox';

// INTERFACE
import { CheckBoxProps } from './CheckBox.interface';

// STYLE
import useClasses from './CheckBox.style';

// COMPONENT
import { Div } from 'components/Div';

const Checkbox: FC<CheckBoxProps> = ({ label, testId, ...props }) => {
  const classes = useClasses({ ...props });

  if (label) {
    return (
      <Div display="flex" flexDirection="row" alignItems="center">
        <MuiCheckbox data-testid={testId} className={classes.nh_customCheckBox} color="primary" {...props} />
        {label}
      </Div>
    );
  }

  return <MuiCheckbox data-testid={testId} className={classes.nh_customCheckBox} color="primary" {...props} />;
};

export default Checkbox;
