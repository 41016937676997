import React, { FC } from 'react';
import { Grid, Divider } from '@material-ui/core';
import { TextDividerProps } from './TextDivider.interface';

const TextDivider: FC<TextDividerProps> = ({ children, textAlign = 'center', dividerStyle, ...props }) => (
  <Grid container alignItems="center" spacing={3} {...props}>
    {textAlign !== 'farLeft' && (
      <Grid item xs={textAlign === 'left' ? 2 : true}>
        <Divider style={dividerStyle} />
      </Grid>
    )}

    <Grid item>{children}</Grid>

    {textAlign !== 'farRight' && (
      <Grid item xs={textAlign === 'right' ? 2 : true}>
        <Divider style={dividerStyle} />
      </Grid>
    )}
  </Grid>
);

export default TextDivider;
