// INTERFACE
import React from 'react';
import { NavigationProps } from 'layout/Navigation/Navigation.interface';
import { ProductType } from 'interface';

import BaseHealthLogo from './assets/ntuc-health-logo.png';
import BaseActiveAgeing from './assets/active_ageing_1.svg';
import BaseActiveAgeingActive from './assets/active_ageing_active.svg';
import BaseHomeCare from './assets/home_care.png';
import BaseDayCare from './assets/day_care.png';
import BaseDentiCare from './assets/denticare.svg';
import BaseDentiCareActive from './assets/denticare_active.svg';
import BaseCommunitySupport from './assets/community_support.png';

export const productCategoriesData: { id: number; img: string; type: ProductType; label: string; activeImg: string }[] =
  [
    {
      id: 1,
      img: BaseActiveAgeing,
      activeImg: BaseActiveAgeingActive,
      type: 'ActiveAgeing',
      label: 'Active Ageing'
    },
    {
      id: 2,
      img: BaseDentiCare,
      activeImg: BaseDentiCareActive,
      type: 'DentiCare',
      label: 'Denticare'
    },
    {
      id: 3,
      img: BaseDayCare,
      activeImg: BaseDayCare,
      type: 'DayCare',
      label: 'Day Care'
    },
    {
      id: 4,
      img: BaseCommunitySupport,
      activeImg: BaseCommunitySupport,
      type: 'CommunitySupport',
      label: 'Community Support'
    },
    {
      id: 5,
      img: BaseHomeCare,
      activeImg: BaseHomeCare,
      type: 'HomeCare',
      label: 'Homecare'
    }
  ];

export const navigationDefaultData: NavigationProps = {
  isAuthenticated: false,
  brand: BaseHealthLogo,
  labels: [
    {
      label: 'ELDERLY CARE',
      members: [],
      onClick: () => console.log('a')
    },
    {
      label: 'ACTIVE AGEING',
      members: [],
      onClick: () => console.log('b')
    },
    {
      label: 'DENTICARE',
      members: [],
      onClick: () => console.log('c')
    },
    {
      label: 'FAMILY MEDICINE',
      members: [],
      onClick: () => console.log('d')
    },
    {
      label: 'ABOUT US',
      members: [],
      onClick: () => console.log('e')
    },
    {
      label: 'JOIN US',
      members: [],
      onClick: () => console.log('f')
    },
    {
      label: 'CONTACT',
      members: [],
      onClick: () => console.log('g')
    }
  ],
  userMenu: [
    { label: 'MY SERVICES', onClick: () => console.log('a') },
    { label: 'MY REQUEST', onClick: () => console.log('b') },
    { label: 'CAREGIVER MANAGEMENT', onClick: () => console.log('b') },
    { label: 'PAYMENT & INVOICE', onClick: () => console.log('d') },
    { label: 'NOTIFICATIONS', onClick: () => console.log('e') },
    { label: 'SETTINGS & PREFERENCES', onClick: () => console.log('f') },
    {
      label: 'FINANCE',
      subItems: [
        { label: 'PAYMENT HISTORY', onClick: () => console.log('payment history') },
        { label: 'CARD MANAGEMENT', onClick: () => console.log('card management') },
        {
          label: 'e-GIRO Arrangements',
          onClick: () => console.log('e-GIRO Arrangements'),
          disabled: true,
          extraComponent: <div>Test</div>
        }
      ]
    }
  ],
  notifications: 10,
  user: {
    id: 1,
    fullName: 'NHAT NGUYEN TUUU',
    userMembers: [
      {
        id: 2,
        fullName: 'NHAT NGUYEN ABC'
      },
      {
        id: 3,
        fullName: 'NHAT NGUYEN BCD'
      }
    ]
  }
};
