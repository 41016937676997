import React, { FC } from 'react';
import Snackbar from '@material-ui/core/Snackbar';

// INTERFACE
import { Alert } from 'components/Alert';
import { ToastProps } from './Toast.interface';

// //STYLE
import useClasses from './Toast.styles';

const Toast: FC<ToastProps> = ({ message, variant, open, onClose, autoHideDuration, isOutlined, showIcon, testId }) => {
  const className = useClasses({ variant });
  return (
    <Snackbar
      data-testid={testId}
      className={className.nh_customSackBar}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
    >
      <Alert showIcon={showIcon} open variant={variant} message={message} isOutlined={isOutlined} onClose={onClose} />
    </Snackbar>
  );
};

Toast.defaultProps = {
  autoHideDuration: 3000
};

export default Toast;
