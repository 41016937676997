import type { Theme } from '@material-ui/core/styles/createTheme';
import { makeStyles } from '@material-ui/core/styles';
import { CatalogNavigationProps } from './CatalogNavigation.interface';

const useCatalogNaviClasses = makeStyles<Theme, Partial<Omit<CatalogNavigationProps, 'scrollPoint'>>, string>(
  ({ ntucHealth }) => ({
    root: {
      boxShadow: (props: Partial<Omit<CatalogNavigationProps, 'scrollPoint'>>) => (props.isTransparent ? 'none' : ''),
      backgroundColor: 'transparent'
    },
    navigationWrapper: {
      justifyContent: 'flex-end',
      alignItems: 'center'
    },
    wrapper: {
      backgroundColor: (props: Partial<Omit<CatalogNavigationProps, 'scrollPoint'>>) =>
        props.showHeroBg ? 'transparent' : '#80BDBD'
    },
    naviBtn: {
      color: ntucHealth.teal700,
      backgroundColor: 'white',
      borderRadius: 3,
      border: `1px solid ${ntucHealth.teal700}`,
      textTransform: 'none',
      fontWeight: 400,
      '&:hover': {
        backgroundColor: ntucHealth.grey200
      }
    },
    itemWrapper: {
      paddingTop: 10,
      backgroundColor: '#fff'
    },
    cartWrapper: {
      position: 'relative',
      padding: 0,
      marginRight: -10
    },
    cartQuantity: {
      borderRadius: '50%',
      width: 24,
      height: 24,
      backgroundColor: '#ff0000',
      position: 'absolute',
      right: 0,
      top: 0,

      '& > MuiButton-label': {
        marginRight: 0
      }
    },
    cartItemsWrapper: {
      borderRadius: 10,
      maxHeight: 500,
      overflowY: 'auto',
      width: 280
    },
    popover: {
      padding: 14,
      marginTop: 10,
      borderRadius: 10,
      backgroundColor: ntucHealth.charcoal700
    },
    checkoutBtnWrapper: {
      position: 'absolute'
    },
    checkoutBtn: {
      marginTop: 20,
      borderRadius: 3,
      textTransform: 'none',
      height: 50,
      backgroundColor: ntucHealth.teal700,
      color: ntucHealth.white,

      '&:hover': {
        backgroundColor: ntucHealth.white,
        color: ntucHealth.green600
      }
    }
  })
);

export default useCatalogNaviClasses;
