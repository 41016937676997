import type { Theme } from '@material-ui/core/styles/createTheme';
import { CardProps, CardHeaderProps, CardFooterProps, CardBodyProps } from './Card.interface';
import { makeStyles } from '@material-ui/core/styles';

// HELPER
import { getColorByColorCode } from 'helpers';

const useCardHeaderClasses = makeStyles<Theme, CardHeaderProps, string>(({ ntucHealth }) => ({
  nh_customCardHeader: {
    cursor: (props: CardHeaderProps) => (props.onHeaderClick ? 'pointer' : 'default'),

    '& .MuiCardHeader-content': {
      // display: 'flex',
      // alignItems: 'center',
      // justifyContent: 'space-between',

      '& .MuiCardHeader-title': {
        color: ntucHealth.charcoal800
      },

      '& .MuiCardHeader-subheader': {
        fontSize: (props: CardHeaderProps) => {
          if (typeof props.subHeader === 'string') {
            return '0.8rem';
          }
        },
        color: (props: CardHeaderProps) => {
          if (typeof props.subHeader === 'string') {
            return ntucHealth.charcoal500;
          }
        }
      }
    },
    borderBottom: (props: CardHeaderProps) => {
      if (props.withBorder) {
        return `2px solid ${ntucHealth.grey200}`;
      }
    },
    borderTop: (props: CardHeaderProps) => {
      if (props.withBorder && props.headerStatus === 'upcoming') {
        return `4px solid ${ntucHealth.teal700}`;
      }

      if (props.withBorder && props.headerStatus === 'completed') {
        return `4px solid ${ntucHealth.green600}`;
      }
    },
    backgroundColor: (props: CardHeaderProps) => {
      if (props.backgroundColor) {
        return getColorByColorCode(props.backgroundColor);
      }

      return ntucHealth.white;
    },
    paddingTop: 16,
    paddingBottom: 16
  }
}));

const useCardClasses = makeStyles<Theme, CardProps, string>(({ ntucHealth }) => ({
  nh_customCard: {
    '&.MuiCard-root': {
      border: `1px solid ${ntucHealth.grey200}`,
      boxShadow: 'rgb(0 0 0 / 20%) 2px 1px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px'
    }
  }
}));

const useCardFooterClasses = makeStyles<Theme, CardFooterProps, string>(() => ({
  nh_customCardFooter: {
    '&.MuiCardActions-root': {
      display: 'block',
      padding: '16px'
    }
  }
}));

const useCardBodyClasses = makeStyles<Theme, CardBodyProps, string>(() => ({
  nh_customCardBody: {
    '&.MuiCardContent-root': {
      padding: 0
    }
  }
}));

export { useCardHeaderClasses, useCardClasses, useCardFooterClasses, useCardBodyClasses };
