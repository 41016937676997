import React, { FC, ReactNode } from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';

interface IProps {
  children: ReactNode;
}

const isFromXsScreen = (): boolean => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.up('xs'));
};

const isXSScreen = (): boolean => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down('xs'));
};

const isFromSmScreen = (): boolean => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.up('sm'));
};

const isSmScreen = (): boolean => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.between('sm', 'sm'));
};

const isFromMdScreen = (): boolean => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.up('md'));
};

const isMdScreen = (): boolean => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.between('md', 'md'));
};

const isFromLgScreen = (): boolean => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.up('lg'));
};

const isLgScreen = (): boolean => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.between('lg', 'lg'));
};

const isFromXlScreen = (): boolean => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.up('xl'));
};

const isXlScreen = (): boolean => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.up('xl'));
};

const XsScreen: FC<IProps> = ({ children }) => {
  const matches = useMediaQuery((t: any) => t.breakpoints.down('xs'));

  return matches ? <>{children}</> : <>{null}</>;
};

const SmScreen: FC<IProps> = ({ children }) => {
  const matches = useMediaQuery((t: any) => t.breakpoints.between('sm', 'sm'));

  return matches ? <>{children}</> : <>{null}</>;
};

const MdScreen: FC<IProps> = ({ children }) => {
  const matches = useMediaQuery((t: any) => t.breakpoints.between('md', 'md'));

  return matches ? <>{children}</> : <>{null}</>;
};

const LgScreen: FC<IProps> = ({ children }) => {
  const matches = useMediaQuery((t: any) => t.breakpoints.between('lg', 'lg'));

  return matches ? <>{children}</> : <>{null}</>;
};

const XLScreen: FC<IProps> = ({ children }) => {
  const matches = useMediaQuery((t: any) => t.breakpoints.up('xl'));

  return matches ? <>{children}</> : <>{null}</>;
};

const betweenCustomScreen = (up: number, down: number): boolean => {
  const theme = useTheme();

  return useMediaQuery(theme.breakpoints.between(up, down));
};

export {
  isFromXsScreen,
  isFromSmScreen,
  isFromMdScreen,
  isFromLgScreen,
  isFromXlScreen,
  XsScreen,
  SmScreen,
  MdScreen,
  LgScreen,
  XLScreen,
  isXSScreen,
  isSmScreen,
  isMdScreen,
  isLgScreen,
  isXlScreen,
  betweenCustomScreen
};
