import React, { FC } from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

// INTERFACE
import { ProgressProps } from './Progress.interface';

// COMPONENT
import { Div } from 'components/Div';

// HOOK
import { isFromSmScreen } from 'hooks/MediaQuery';

// STYLE
import useClasses from './Progress.styles';

const Progress: FC<ProgressProps> = ({ steps, activeStep, testId }) => {
  const isSmScreen = isFromSmScreen();
  const classes = useClasses({ steps, activeStep });

  if (isSmScreen) {
    return (
      <Div>
        <Stepper data-testid={testId} activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label} className={classes.nh_customProgressStepWrapper}>
              <StepLabel className={classes.nh_customProgressStepLabelWrapper}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Div>
    );
  }

  return (
    <Div>
      <Stepper
        data-testid={testId}
        activeStep={activeStep}
        alternativeLabel
        className={classes.nh_customProgressLabelWrapper}
      >
        {steps.map((label) => (
          <Step key={label} className={classes.nh_customProgressStepWrapper}>
            <StepLabel className={classes.nh_customProgressStepLabelWrapper} />
          </Step>
        ))}
      </Stepper>
    </Div>
  );
};
Progress.defaultProps = {
  steps: []
};

export default Progress;
