import type { Theme } from '@material-ui/core/styles/createTheme';
import { ProgressProps } from './Progress.interface';
import { makeStyles } from '@material-ui/core/styles';

const useClasses = makeStyles<Theme, ProgressProps, string>(({ ntucHealth }) => ({
  nh_customProgressLabelWrapper: {},
  nh_customProgressStepWrapper: {
    '& .MuiStepIcon-root': {
      color: ntucHealth.grey200,

      '&.MuiStepIcon-active': {
        fill: ntucHealth.green600,

        '& > .MuiStepIcon-text': {
          fill: ntucHealth.white
        }
      },

      '&.MuiStepIcon-completed': {
        fill: ntucHealth.green600
      },

      '& > .MuiStepIcon-text': {
        fill: ntucHealth.charcoal800
      }
    }
  },
  nh_customProgressStepLabelWrapper: {
    '& .MuiStepLabel-label': {
      color: ntucHealth.charcoal800
    }
  }
}));

export default useClasses;
