import type { Theme } from '@material-ui/core/styles/createTheme';
import { DatePickerProps } from './DatePicker.interface';
import type { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { MainColor } from '@material-ui/core/styles/createTheme';

const commonStyle = (ntucHealth: MainColor, value: ParsableDate, disabled?: boolean) => {
  return {
    '&.Mui-disabled': {
      // backgroundColor: ntucHealth.grey200,
      '&:before': {
        borderBottom: 'unset'
      }
    },
    border: `1px solid ${ntucHealth.charcoal300}`,
    borderRadius: 0,
    '&.MuiFormControl-marginNormal': {
      marginTop: 0,
      marginBottom: 0
    },
    boxShadow: '0px 1px 1px rgba(152, 162, 179, 0.1), 0px 3px 2px rgba(152, 162, 179, 0.1)',
    '& > .MuiInputBase-root': {
      marginBottom: 0,
      border: `1px solid ${ntucHealth.charcoal300}`,
      backgroundColor: () => {
        if (!disabled && value && String(value).length > 0) {
          return 'white';
        }

        return ntucHealth.charcoal100;
      },
      height: 43,
      '&:hover': {
        '&:before': {
          borderBottom: 'unset'
        }
      },
      '&:before': {
        borderBottom: 'unset'
      }
    }
  };
};

export const useClasses = makeStyles<Theme, DatePickerProps, string>(({ ntucHealth }: Theme) => ({
  nh_customDatePickerField: (props: DatePickerProps) => ({
    ...commonStyle(ntucHealth, props.value, props.disabled)
  }),
  nh_customRequiredField: {
    color: ntucHealth.red900
  }
}));

export const useClassesStaticMode = makeStyles<Theme, Record<string, unknown>, string>(({ ntucHealth }: Theme) => {
  const windownWidth = window.innerWidth;
  let dayContentSize = {
    width: 0,
    height: 0
  };
  let fontSize = 0;
  let calendarTop = 0;
  let weekDayTop = 0;

  if (windownWidth <= 320) {
    dayContentSize = {
      width: 30,
      height: 30
    };

    fontSize = 14;
    calendarTop = 5;
    weekDayTop = 80;
  }

  if (windownWidth <= 375 && windownWidth > 320) {
    dayContentSize = {
      width: 35,
      height: 35
    };

    fontSize = 14;
    calendarTop = 5;
    weekDayTop = 80;
  }

  if (windownWidth <= 500 && windownWidth > 375) {
    dayContentSize = {
      width: 45,
      height: 45
    };

    fontSize = 16;
    calendarTop = 5;
    weekDayTop = 80;
  }

  if (windownWidth <= 600 && windownWidth > 500) {
    dayContentSize = {
      width: 30,
      height: 30
    };

    fontSize = 14;
    calendarTop = 5;
    weekDayTop = 80;
  }

  if (windownWidth <= 768 && windownWidth > 600) {
    dayContentSize = {
      width: 33,
      height: 33
    };

    fontSize = 16;
    calendarTop = 5;
    weekDayTop = 80;
  }

  if (windownWidth > 768) {
    dayContentSize = {
      width: 35,
      height: 35
    };

    fontSize = 16;
    calendarTop = 5;
    weekDayTop = 80;
  }

  return {
    nh_customDateStatic: {
      '& .CalendarDay__default': {
        color: 'inherit',
        border: 'none',
        // width: 10,

        '&:hover': {
          border: 'none',
          backgroundColor: 'unset'
        },

        '& > .nh_customDayContent': {
          width: dayContentSize.width,
          height: dayContentSize.height,
          marginLeft: 3,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',

          '&.disabled': {
            color: ntucHealth.charcoal500
          }
        }
      },

      '& .CalendarDay': {
        fontFamily: 'Open Sans',
        fontWeight: 300,
        fontSize: fontSize
      },

      '& .CalendarMonth_caption': {
        fontFamily: 'Open Sans',
        paddingBottom: 60
      },

      '& .DayPicker_weekHeader': {
        fontFamily: 'Open Sans',
        textTransform: 'uppercase',
        top: weekDayTop
      },

      '& .CalendarMonthGrid__horizontal': {
        top: calendarTop
      }
    }
  };
});
