import React, { FC } from 'react';

// INTERFACE
import { PageProps } from './Page.interface';

// COMPONENT
import { Div } from 'components/Div';
import { Container } from 'components/Container';
import { Navigation } from 'layout/Navigation';
import { CatalogNavigation } from 'layout/CatalogNavigation';
import { ContactUs } from 'components/ContactUs';

import { Footer } from 'layout/Footer';

const Page: FC<PageProps> = ({
  children,
  containerSmall,
  navigationProps,
  footerProps,
  catalogNavigationProps,
  hasContactUs,
  contactUsProps,
  fullWidth,
  catalogNavigation
}) => {
  return (
    <Div display="flex" flexDirection="column" minHeight="100vh">
      {catalogNavigation ? (
        <CatalogNavigation {...catalogNavigationProps} />
      ) : (
        <Navigation notifications={0} {...navigationProps} />
      )}

      <Div display="flex" flex={1} padding={{ bottom: 50 }}>
        {fullWidth ? children : <Container isSmall={containerSmall}>{children}</Container>}
      </Div>

      {hasContactUs && <ContactUs {...contactUsProps} />}

      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', flexShrink: 0 }}>
        <Footer {...footerProps} />
      </div>
    </Div>
  );
};

export default Page;
