import React, { FC } from 'react';
import MuiRadioGroup from '@material-ui/core/RadioGroup';

// INTERFACE
import { RadioGroupProps } from './RadioGroup.interface';

// STYLE
import useClasses from './RadioGroup.style';

const RadioGroup: FC<RadioGroupProps> = ({ testId, ...props }) => {
  const classes = useClasses({ ...props });

  return <MuiRadioGroup data-testid={testId} className={classes.nh_customRadioGroup} {...props} />;
};

export default RadioGroup;
