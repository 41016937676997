import type { TypographyOptions } from '@material-ui/core/styles/createTypography';

// Palette
import palette from '../palette';

import './index.scss';

const fontFamily = ['Open Sans', 'sans-serif'].join(', ');

const typography: TypographyOptions = {
  fontFamily,
  h1: {
    fontFamily,
    color: palette.text?.primary,
    fontWeight: 600,
    fontSize: 28,
    display: 'block'
  },
  h2: {
    fontFamily,
    color: palette.text?.primary,
    fontWeight: 600,
    fontSize: 24,
    display: 'block'
  },
  h3: {
    fontFamily,
    color: palette.text?.primary,
    fontWeight: 600,
    fontSize: 18,
    display: 'block'
  },
  h4: {
    fontFamily,
    color: palette.text?.primary,
    fontWeight: 600,
    fontSize: 16,
    display: 'block'
  },
  body1: {
    fontFamily,
    color: palette.text?.primary,
    fontWeight: 400,
    fontSize: 16,
    display: 'block'
  },
  body2: {
    fontFamily,
    color: palette.text?.primary,
    fontWeight: 400,
    fontSize: 14,
    display: 'block'
  },
  button: {
    color: palette.text?.primary,
    fontWeight: 700,
    fontSize: '14px'
  }
};

export default typography;
