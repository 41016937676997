import type { Theme } from '@material-ui/core/styles/createTheme';
import { makeStyles } from '@material-ui/core/styles';
import { ProductDetailCardProps } from './ProductDetailCard.interface';
import { getColorByColorCode } from 'helpers';

const useProductDetailCardClasses = makeStyles<Theme, ProductDetailCardProps, string>(() => ({
  customCard: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '15px',
    boxShadow: '0 2px 6px 0 rgba(0, 0, 0, 0.16)',
    maxWidth: 300,
    padding: 10,
    textAlign: 'center',
    width: '100%',
    boxSizing: 'border-box'
  },
  customCardContent: {
    paddingTop: 0,
    height: (props: ProductDetailCardProps) => (props.isInCart ? 'auto' : 145),
    overflow: 'hidden'
  },
  customCardHeader: {
    color: (props: ProductDetailCardProps) => `${getColorByColorCode(props.titleColor)}`,
    fontWeight: 900,
    height: 60,
    marginBottom: 0,
    fontSize: 20,
    overflow: 'hidden'
  },
  customCardFooter: {
    paddingTop: 10
  }
}));

export { useProductDetailCardClasses };
