import React, { FC, useCallback } from 'react';
import { IconButton, MenuItem, Select } from '@material-ui/core';
import { Typography } from 'components/Typography';
import { ResourceHeaderProps } from './Calendar.interface';
import styled from '@emotion/styled';
import { Cancel } from '@material-ui/icons';
import { Div } from 'components/Div';

const StyledContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 14px;
  background-color: rgb(246, 251, 254);
  margin-top: 25px;

  .MuiInput-underline:before {
    border-bottom: none;
  }

  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: none;
  }

  .MuiInput-underline:after {
    border-bottom: none;
  }

  .MuiInputBase-input {
    text-align: left;
  }

  .MuiSelect-select:focus {
    background-color: transparent;
  }
`;

const StyledButtonContainer = styled(Div)`
  position: absolute;
  top: -25px;
  left: 0;
  right: 0;
  margin-right: auto;
  margin-left: auto;
`;

const ResourceHeader: FC<ResourceHeaderProps & { closeDisabled?: boolean }> = ({ closeDisabled, ...props }) => {
  const renderSelectedItem = useCallback(
    (v: any) => {
      if (v) {
        const resource = props.originalResourceMap?.find((r) => r.resourceId === v);
        if (resource) {
          return (
            <Typography color="teal" variant="h3">
              {resource.title}
            </Typography>
          );
        }
      }

      return null;
    },
    [props.originalResourceMap]
  );

  return (
    <StyledContainer>
      {!closeDisabled && (
        <StyledButtonContainer>
          <IconButton onClick={() => props.deleteOnPress(props.index)}>
            <Cancel color="action" />
          </IconButton>
        </StyledButtonContainer>
      )}
      <Select
        name={`resource.${props.index}`}
        id={`resource.${props.index}`}
        value={props.resource.resourceId}
        onChange={(event) => props.selectOnChange(props.index, event.target.value as string)}
        renderValue={renderSelectedItem}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left'
          },
          getContentAnchorEl: null
        }}
      >
        {props.originalResourceMap?.map((val) => (
          <MenuItem value={val.resourceId} key={val.resourceId}>
            <Div>
              <Typography variant="body2">{val.title}</Typography>
              <Typography variant="caption">{val.role}</Typography>
            </Div>
          </MenuItem>
        ))}
      </Select>
      <Typography variant="caption" align="left">
        {props.resource.role}
      </Typography>
    </StyledContainer>
  );
};

export default ResourceHeader;
