import type { Theme } from '@material-ui/core/styles/createTheme';
import { AlertProps } from './Alert.interface';
import { makeStyles } from '@material-ui/core/styles';

const useClasses = makeStyles<Theme, Pick<AlertProps, 'variant'>, string>(({ ntucHealth }: Theme) => ({
  nh_customAlert: {
    backgroundColor: (props: Pick<AlertProps, 'variant'>) => {
      if (props.variant === 'success') {
        return ntucHealth.green600;
      }

      if (props.variant === 'warning') {
        return ntucHealth.yellowOrange;
      }

      if (props.variant === 'info') {
        return ntucHealth.teal700;
      }

      if (props.variant === 'error') {
        return ntucHealth.red900;
      }

      if (props.variant === 'support') {
        return ntucHealth.limeGreen;
      }

      return ntucHealth.charcoal900;
    },
    color: (props: Pick<AlertProps, 'variant'>) => {
      if (props.variant === 'support') {
        return ntucHealth.greyBlack;
      }
    }
  }
}));

export default useClasses;
