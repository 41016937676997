import { makeStyles } from '@material-ui/core/styles';
import type { Theme } from '@material-ui/core/styles/createTheme';

const useFullscreenModalClasses = makeStyles<Theme, Record<string, unknown>, string>(() => ({
  modal: {
    backdropFilter: 'blur(25px) saturate(200%)',
    WebkitBackdropFilter: 'blur(25px) saturate(200%)',
    backgroundColor: 'rgba(255, 255, 255, 0.75)',
    borderLeft: '1px solid rgba(0, 0, 0, 0.125)',
    borderRight: '1px solid rgba(0, 0, 0, 0.125)',
    width: '100%',
    height: '100%',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  closeBtn: {
    position: 'absolute',
    right: 50,
    top: 10
  }
}));

export default useFullscreenModalClasses;
