import type { Theme } from '@material-ui/core/styles/createTheme';
import { TextFieldProps } from './TextField.interface';
import { makeStyles } from '@material-ui/core/styles';

const useClasses = makeStyles<Theme, TextFieldProps, string>(({ ntucHealth }: Theme) => ({
  nh_customTextField: {
    '& .MuiFilledInput-inputHiddenLabel': {
      paddingRight: (props: TextFieldProps) => {
        if (props.clearAll) {
          return 50;
        }

        return 32;
      },
      paddingTop: 11,
      paddingBottom: (props: TextFieldProps) => {
        if (
          props.multiple &&
          !props.disabled &&
          Array.isArray(props.value) &&
          props.value.filter((v) => v !== 'none').length > 0
        ) {
          return 6;
        }

        return 11;
      }
    },
    '&.Mui-disabled': {
      // backgroundColor: ntucHealth.grey200,
      '&:before': {
        borderBottom: 'unset'
      }
    },
    border: `1px solid ${ntucHealth.charcoal300}`,
    borderRadius: 0,
    '& > .MuiInputBase-root': {
      marginBottom: 0,
      backgroundColor: (props: TextFieldProps) => {
        if (
          !props.multiple &&
          (typeof props.value === 'string' || typeof props.value === 'number') &&
          !props.disabled &&
          String(props.value).length > 0
        ) {
          return 'white';
        }

        if (
          !props.multiple &&
          (typeof props.value === 'string' || typeof props.value === 'number') &&
          !props.disabled &&
          String(props.value).length === 0
        ) {
          return ntucHealth.charcoal100;
        }

        if (
          props.multiple &&
          !props.disabled &&
          Array.isArray(props.value) &&
          props.value.filter((v) => v !== 'none').length > 0
        ) {
          return 'white';
        }

        if (
          props.multiple &&
          !props.disabled &&
          Array.isArray(props.value) &&
          props.value.filter((v) => v !== 'none').length === 0
        ) {
          return ntucHealth.charcoal100;
        }

        return ntucHealth.grey200;
      }
    },
    '&:hover': {
      backgroundColor: (props: TextFieldProps) => {
        if (!props.disabled) {
          return 'white';
        }

        return ntucHealth.grey200;
      },
      borderBottom: `1px solid ${ntucHealth.charcoal300}`,
      '&:before': {
        borderBottom: 'unset'
      }
    },
    '&.Mui-focused': {
      backgroundColor: (props: TextFieldProps) => {
        if (!props.disabled) {
          return 'white';
        }

        return ntucHealth.grey200;
      }
    },
    boxShadow: '0px 1px 1px rgba(152, 162, 179, 0.1), 0px 3px 2px rgba(152, 162, 179, 0.1)',
    '&.MuiFilledInput-adornedEnd': {
      paddingRight: 0
    },
    '&:before': {
      borderBottom: 'unset'
    },
    '& .MuiSelect-selectMenu:focus': {
      backgroundColor: 'transparent'
    },
    '& .MuiFormHelperText-contained': {
      marginTop: 6,
      marginLeft: 0,
      marginRight: 0
    },
    '& .MuiFilledInput-multiline': {
      paddingTop: 0
    }
  },
  nh_customSelectInputSelect: {
    width: 140,
    paddingLeft: 10,
    backgroundColor: (props: TextFieldProps) => {
      if (
        !props.multiple &&
        !props.disabled &&
        (typeof props.dropDownValue === 'string' || typeof props.dropDownValue === 'number') &&
        String(props.dropDownValue).length > 0
      ) {
        return 'white';
      }

      return `${ntucHealth.grey200}`;
    },
    '&.Mui-disabled': {
      backgroundColor: ntucHealth.grey200,
      '&:before': {
        borderBottom: 'unset'
      }
    },
    '&.MuiFilledInput-adornedStart': {
      paddingLeft: 0
    },
    border: `2px solid ${ntucHealth.charcoal300}`,
    borderRight: 'none',
    borderRadius: 0,
    '&:hover': {
      backgroundColor: (props: TextFieldProps) => {
        if (!props.disabled) {
          return 'white';
        }

        return ntucHealth.grey200;
      },
      '&:before': {
        borderBottom: 'unset'
      }
    },
    '&.Mui-focused': {
      backgroundColor: (props: TextFieldProps) => {
        if (!props.disabled) {
          return 'white';
        }

        return ntucHealth.grey200;
      }
    },
    boxShadow: '0px 1px 1px rgba(152, 162, 179, 0.1), 0px 3px 2px rgba(152, 162, 179, 0.1)',
    '&:before': {
      borderBottom: 'unset'
    },
    '& .MuiSelect-selectMenu:focus': {
      backgroundColor: 'transparent'
    },
    '&.MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: 'unset'
    },
    '& > .MuiSelect-icon': {
      right: 5
    }
  },
  nh_customChipWrapper: {
    marginRight: 10,
    marginBottom: 5,
    whiteSpace: 'normal',
    wordBreak: 'break-all'
  },
  nh_customPlaceHolder: {
    color: 'rgba(0, 0, 0, 0.38)'
  },
  nh_customRequiredField: {
    color: ntucHealth.red900
  },
  nh_customMenuItem: {
    whiteSpace: 'unset',
    wordBreak: 'break-all'
  },
  close_btn: {
    display: 'flex',
    justifyContent: 'flex-end',
    cursor: 'default',

    '&:hover': {
      backgroundColor: 'initial'
    },

    '&>button': {
      marginRight: '20px',
      cursor: 'pointer'
    }
  }
}));

export default useClasses;
