import React, { FC, useState } from 'react';
import { GrDown, GrUp } from 'react-icons/gr';

// COMPONENT
import Typography from 'components/Typography/Typo';
import { Div } from 'components/Div';
import { Avatar } from 'components/Avatar';
import { Button } from 'components/Button';
import { Collapse } from 'components/Collapse';

// INTERFACE
import { NavigationItemDropDownProps, UserRowProps, CollapseMenuItemProps } from './Navigation.interface';

export const CollapseMenuItem: FC<CollapseMenuItemProps> = ({
  label,
  onClick,
  subItems,
  color = 'grey',
  iconColor
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const onShowHideSubMenu = () => {
    setIsExpanded((prev) => !prev);
  };

  return (
    <Div>
      <Div
        backgroundColor={color}
        height={50}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexDirection="row"
        padding={{ all: 10 }}
        onCLick={onShowHideSubMenu}
      >
        <Typography variant="body2" bold color="primary" hoverColor="secondary">
          {label}
        </Typography>
        {isExpanded ? (
          <GrUp color={iconColor ? iconColor : 'primary'} />
        ) : (
          <GrDown color={iconColor ? iconColor : 'primary'} />
        )}
      </Div>
      <Collapse isOpen={isExpanded}>
        {subItems?.map((subMenu, index) => (
          <Div
            key={`${subMenu.label}-${index}`}
            backgroundColor={color}
            height={50}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flexDirection="row"
            padding={{ all: 10, left: 20 }}
          >
            {subMenu.disabled ? (
              <Div display="flex" flexDirection="row" alignItems="center">
                <Typography
                  variant="body2"
                  bold
                  color="primary"
                  hoverColor="secondary"
                  style={{
                    color: '#ccc'
                  }}
                >
                  {subMenu.label}
                </Typography>
                {subMenu.extraComponent}
              </Div>
            ) : (
              <Div display="flex" flexDirection="row" alignItems="center">
                <Typography
                  variant="body2"
                  bold
                  color="primary"
                  hoverColor="secondary"
                  onClick={() => {
                    onClick && onClick();
                    subMenu.onClick && subMenu.onClick();
                  }}
                >
                  {subMenu.label}
                </Typography>
                {subMenu.extraComponent}
              </Div>
            )}
          </Div>
        ))}
      </Collapse>
    </Div>
  );
};

export const NavigationItemDropDown: FC<NavigationItemDropDownProps> = ({
  label,
  onClick,
  withDownIcon,
  notifications,
  color = 'grey'
}) => {
  return (
    <Div
      backgroundColor={color}
      height={50}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      flexDirection="row"
      padding={{ all: 10 }}
    >
      <Typography variant="body2" bold color="primary" onClick={onClick} hoverColor="secondary">
        {label}
      </Typography>
      {withDownIcon && (
        <Div padding={{ right: 25 }}>
          <GrDown />
        </Div>
      )}
      {notifications && notifications > 0 ? (
        <Div padding={{ right: 20 }}>
          <Avatar>
            <Typography variant="body1">{String(notifications)}</Typography>
          </Avatar>
        </Div>
      ) : null}
    </Div>
  );
};

export const UserRow: FC<UserRowProps> = ({ user, onSelectProfile, color }) => {
  return (
    <Div
      backgroundColor={color}
      height={50}
      display="flex"
      alignItems="center"
      flexDirection="row"
      padding={{ all: 10 }}
      onCLick={() => onSelectProfile && onSelectProfile(user.id)}
    >
      <Avatar url={user.avatar} />
      <Div margin={{ left: 10 }}>
        <Typography bold color="primary" variant="body2" align="center">
          {user.fullName.toUpperCase()}
        </Typography>
      </Div>
    </Div>
  );
};

export const ManageUserProfile: FC<Pick<UserRowProps, 'onSelectProfile' | 'color'>> = ({ onSelectProfile, color }) => {
  return (
    <Div
      backgroundColor={color}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      flexDirection="row"
      padding={{ all: 10 }}
    >
      <Div backgroundColor="white" width="100%">
        <Button variant="secondary" fullWidth onClick={() => onSelectProfile && onSelectProfile(-1)}>
          MANAGE THEIR PROFILE
        </Button>
      </Div>
    </Div>
  );
};
