import type { Theme } from '@material-ui/core/styles/createTheme';
import { makeStyles } from '@material-ui/core/styles';
import { getColorByColorCode, getRgba } from 'helpers';
import { GradientColorProps } from './GradientColor.interface';

const useClasses = makeStyles<Theme, GradientColorProps, string>(() => ({
  gradient: {
    background: ({ colors = [] }: GradientColorProps) =>
      `linear-gradient(to right, ${colors.map((c) => getColorByColorCode(c)).toString()})`
  },
  transparent: {
    background: ({ colors = [], alpha = 1 }: GradientColorProps) =>
      `linear-gradient(to right, ${colors.map((c) => getRgba(getColorByColorCode(c), alpha)).toString()})`
  }
}));

export default useClasses;
