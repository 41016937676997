import React, { FC } from 'react';
import { Grid } from '@material-ui/core';

// INTERFACE
import { FooterProps } from './Footer.interface';
import { NavigationLabel } from 'interface';

// STYLE
import useClasses from './Footer.style';

// COMPONENT
import { Typography } from 'components/Typography';
import { isFromMdScreen, betweenCustomScreen } from 'hooks/MediaQuery';
import { Div } from 'components/Div';

// DEFAULT DATA
import { footerDefaultData } from 'data';

const FooterMember: FC<NavigationLabel> = ({ label, members }) => {
  const baseClasses = useClasses({});

  return (
    <Grid item xs={12} sm={6} md={3}>
      <Div className={baseClasses.flexMainTitle}>
        <Typography bold color="secondary">
          {label}
        </Typography>
      </Div>
      {members.map((v, i) => (
        <Typography key={i} color="white" gutterBottom onClick={v.onClick} hoverColor="white">
          {v.label}
        </Typography>
      ))}
    </Grid>
  );
};

const Footer: FC<FooterProps> = ({
  address = '',
  phone = '',
  workingDay = '',
  workingHour = '',
  brand = '',
  footerSocials = [],
  labels = [],
  onPrivacyPolicyClick,
  onBrandClick,
  onFAQClick,
  customInfo,
  FAQText,
  brandName
}) => {
  const baseClasses = useClasses({});
  const isMdScreen = isFromMdScreen();
  const laptopScreen = betweenCustomScreen(769, 1025);

  return (
    <Div className={baseClasses.footerWrapper} padding={{ all: 50 }} overflowX="hidden" gridContainer>
      <Grid container spacing={5}>
        {(address || phone || workingHour || workingDay || customInfo) && (
          <Grid item xs={12} sm={6} md={4}>
            {customInfo && customInfo}
          </Grid>
        )}

        {labels.map((v, i) => (
          <FooterMember key={i} label={v.label} members={v.members} />
        ))}
      </Grid>
      <Grid container className={baseClasses.brandWrapper}>
        {isMdScreen ? (
          <>
            <Grid className={baseClasses.brand} item xs={12} sm={12} md={9}>
              <Div>
                <Div display="flex" flexDirection="row" height="100%" alignItems="center">
                  <Typography color="white">{brand}</Typography>
                  <Div margin={{ left: 5, right: 5 }}>
                    <Typography color="white">|</Typography>
                  </Div>
                  <Div margin={{ left: 5, right: 5 }}>
                    <Typography color="white" onClick={onPrivacyPolicyClick} hoverColor="white">
                      Privacy Policy
                    </Typography>
                  </Div>
                  {!laptopScreen && (
                    <>
                      <Div margin={{ left: 5, right: 5 }}>
                        <Typography color="white">|</Typography>
                      </Div>
                      <Typography color="white" onClick={onBrandClick} hoverColor="white">
                        {brandName}
                      </Typography>
                      {FAQText && (
                        <>
                          <Div margin={{ left: 5, right: 5 }}>
                            <Typography color="white">|</Typography>
                          </Div>
                          <Typography color="white" onClick={onFAQClick} hoverColor="white">
                            {FAQText}
                          </Typography>
                        </>
                      )}
                    </>
                  )}
                </Div>
                {laptopScreen && (
                  <Div display="flex" flexDirection="row" height="100%" alignItems="center">
                    <Div margin={{ right: 5 }}>
                      <Typography color="white">|</Typography>
                    </Div>
                    <Typography color="white" onClick={onBrandClick} hoverColor="white">
                      {brandName}
                    </Typography>
                    {FAQText && (
                      <>
                        <Div margin={{ left: 5, right: 5 }}>
                          <Typography color="white">|</Typography>
                        </Div>
                        <Typography color="white" onClick={onFAQClick} hoverColor="white">
                          {FAQText}
                        </Typography>
                      </>
                    )}
                  </Div>
                )}
              </Div>
            </Grid>
            {footerSocials.length > 0 && (
              <>
                <Grid className={baseClasses.flexSocialWrapper} item xs={12} sm={12} md={3}>
                  <Div display="flex" flexDirection="row" alignItems={laptopScreen ? 'flex-start' : 'center'}>
                    <Div margin={{ right: 5 }}>
                      <Typography fontWeight="semibold" color="white">
                        Get Social:
                      </Typography>
                    </Div>
                    {footerSocials.map((v, i) => (
                      <span key={i} className={baseClasses.iconWrapper} onClick={() => window.open(v.link, '_blank')}>
                        {v.icon}
                      </span>
                    ))}
                  </Div>
                </Grid>
              </>
            )}
          </>
        ) : (
          <>
            {footerSocials.length > 0 && (
              <Grid className={baseClasses.flexSocialWrapper} item xs={12} sm={12} md={3}>
                <Typography bold color="white" gutterBottom align="center">
                  Get Social:
                </Typography>
              </Grid>
            )}
            <Grid className={baseClasses.flexSocialWrapper} item xs={12} sm={12} md={3}>
              {footerSocials.map((v, i) => (
                <span key={i} className={baseClasses.iconWrapper} onClick={() => window.open(v.link, '_blank')}>
                  {v.icon}
                </span>
              ))}
            </Grid>
            <Grid className={baseClasses.brand} item xs={12} sm={12} md={9}>
              <Div display="flex" flexDirection="column">
                <Typography color="white" align="center" onClick={onBrandClick} hoverColor="white">
                  {brandName}
                </Typography>
                <Typography color="white" align="center" onClick={onPrivacyPolicyClick} hoverColor="white">
                  Privacy Policy
                </Typography>
                <Typography color="white" align="center" onClick={onFAQClick} hoverColor="white">
                  FAQ
                </Typography>
                <Typography color="white" align="center">
                  {brand}
                </Typography>
              </Div>
            </Grid>
          </>
        )}
      </Grid>
    </Div>
  );
};

Footer.defaultProps = {
  ...footerDefaultData
};

export default Footer;
