import { Box, CircularProgress } from '@material-ui/core';
import { Typography } from 'components/Typography';
import React from 'react';
import { CircleProps } from './Circle.interface';
import useClasses from './Circle.styles';

const Circle: React.FC<CircleProps> = ({ value, textColor, barColor, size, thickness }) => {
  const classes = useClasses({ value });

  return (
    <Box className={classes.wrapper}>
      <CircularProgress
        size={size}
        color="inherit"
        variant="determinate"
        thickness={thickness}
        value={100}
        className={classes.backgroundBar}
      />
      <CircularProgress
        size={size}
        color={barColor}
        variant="determinate"
        thickness={thickness}
        value={value}
        className={classes.mainBar}
      />
      <Box className={classes.textContainer}>
        <Typography bold color={textColor}>{`${Math.round(value)}%`}</Typography>
      </Box>
    </Box>
  );
};

Circle.defaultProps = {
  barColor: 'secondary',
  textColor: 'primary',
  size: 70,
  thickness: 4
};

export default Circle;
