import { ProductDetailCardProps } from './ProductDetailCard.interface';
import React, { FC } from 'react';
import { Card, CardActions, CardContent, CardHeader } from '@material-ui/core';
import { useProductDetailCardClasses } from './ProductDetailCard.styles';

const ProductDetailCard: FC<ProductDetailCardProps> = ({ title, titleColor, content, footer, isInCart }) => {
  const classes = useProductDetailCardClasses({ title, titleColor, isInCart });

  return (
    <Card className={classes.customCard}>
      <CardHeader
        title={title}
        titleTypographyProps={{ className: classes.customCardHeader }}
        style={{ paddingBottom: 0 }}
      />
      <CardContent className={classes.customCardContent}>{content}</CardContent>
      <CardActions className={classes.customCardFooter}>{footer}</CardActions>
    </Card>
  );
};

ProductDetailCard.defaultProps = {
  titleColor: 'primary'
};

export { ProductDetailCard };
