import type { Theme, MainColor } from '@material-ui/core/styles/createTheme';
import type { Spacing } from '@material-ui/core/styles/createSpacing';
import { AvatarProps } from './Avatar.interface';
import { makeStyles } from '@material-ui/core/styles';
import { Color } from 'interface';

// HELPER
import { getColorByColorCode } from 'helpers';

const style = (
  ntucHealth: MainColor,
  spacingNumber: number,
  spacing: Spacing,
  backgroundColor: Color,
  url?: string
) => {
  return {
    width: spacing(spacingNumber),
    height: spacing(spacingNumber),
    backgroundColor: getColorByColorCode(backgroundColor),
    '& > img': {
      width: url ? '100%' : '60%',
      height: url ? '100%' : '60%'
    }
  };
};

const useClasses = makeStyles<Theme, Pick<AvatarProps, 'size' | 'url' | 'backgroundColor'>, string>(
  ({ ntucHealth, spacing }) => ({
    xs: (props: AvatarProps) => {
      return {
        ...style(ntucHealth, 2, spacing, props.backgroundColor ? props.backgroundColor : 'grey', props.url)
      };
    },
    sm: (props: AvatarProps) => {
      return {
        ...style(ntucHealth, 4, spacing, props.backgroundColor ? props.backgroundColor : 'grey', props.url)
      };
    },
    md: (props: AvatarProps) => {
      return {
        ...style(ntucHealth, 8, spacing, props.backgroundColor ? props.backgroundColor : 'grey', props.url)
      };
    },
    lg: (props: AvatarProps) => {
      return {
        ...style(ntucHealth, 12, spacing, props.backgroundColor ? props.backgroundColor : 'grey', props.url)
      };
    },
    xl: (props: AvatarProps) => {
      return {
        ...style(ntucHealth, 16, spacing, props.backgroundColor ? props.backgroundColor : 'grey', props.url)
      };
    }
  })
);

export default useClasses;
