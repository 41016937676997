/* eslint-disable react/display-name */

import React, { FC, useState } from 'react';
import Slider, { Settings } from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { CarouselProps } from './Carousel.interface';

const Carousel: FC<CarouselProps> = ({ children, settings }) => {
  const [active, setActive] = useState(0);

  const defaultSettings: Settings = {
    dots: true,
    autoplay: true,
    beforeChange: (old, newIndex) => setActive(newIndex),
    customPaging: (i) => {
      return (
        <a>
          <div
            style={{
              width: '12px',
              height: '12px',
              margin: '0 10px',
              border: 'solid 1px #65b444',
              borderRadius: '50%',
              backgroundColor: i === active ? '#2a9ca7' : 'white',
              marginTop: 35
            }}
          ></div>
        </a>
      );
    }
  };

  return <Slider {...{ ...defaultSettings, ...settings }}>{children}</Slider>;
};

export default Carousel;
