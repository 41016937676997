import React, { FC } from 'react';
import clsx from 'clsx';
import { ButtonProps } from './Button.interface';
import { Button as MuiButton } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

// COMPONENT
import { Div } from 'components/Div';

//STYLE
import useClasses from './Button.styles';

const Button: FC<ButtonProps> = ({
  children,
  variant,
  disabled,
  isBlackBorder,
  withoutBorder,
  isGreenBg,
  type,
  withoutPadding,
  fullHeight,
  loading,
  fullWidth,
  className,
  testId,
  ...rest
}) => {
  let muiVariant: 'text' | 'outlined' | 'contained';

  muiVariant = variant === 'primary' ? 'contained' : 'outlined';

  if (withoutBorder) {
    muiVariant = 'text';
  }

  const baseClasses = useClasses({
    children,
    variant,
    disabled,
    isBlackBorder,
    withoutBorder,
    isGreenBg,
    type,
    withoutPadding,
    fullHeight,
    ...rest
  });

  const mainClassName = clsx(baseClasses.nh_customButton, className);

  const basedComponent = (
    <MuiButton
      data-testid={testId}
      className={mainClassName}
      disabled={loading ? true : disabled}
      color={isBlackBorder ? 'default' : 'primary'}
      variant={muiVariant}
      type={type}
      fullWidth={fullWidth}
      {...rest}
    >
      {children}
    </MuiButton>
  );

  if (loading && fullWidth) {
    return (
      <Div className={baseClasses.wrapper}>
        {basedComponent}
        {loading && <CircularProgress size={24} className={baseClasses.buttonProgress} />}
      </Div>
    );
  }

  if (loading && !fullWidth) {
    return (
      <Div className={baseClasses.wrapper} display="inline">
        {basedComponent}
        {loading && <CircularProgress size={24} className={baseClasses.buttonProgress} />}
      </Div>
    );
  }

  return basedComponent;
};
Button.defaultProps = {
  variant: 'primary',
  size: 'large'
};

export default Button;
