import React, { FC, useState, ReactNode, KeyboardEvent, KeyboardEventHandler, useMemo, useCallback } from 'react';
import { TextField as MuiTextField, MenuItem, IconButton, Chip } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ClearIcon from '@material-ui/icons/Clear';
import Select from '@material-ui/core/Select';

// COMPONENT
import { Div } from 'components/Div';
import { Typography } from 'components/Typography';
import { Button } from 'components/Button';

// INTERFACE
import { TextFieldProps } from './TextField.interface';

// STYLE
import useClasses from './TextField.styles';

const TextField: FC<TextFieldProps> = ({
  actionButtonTitle,
  clearAll,
  dropDownValue,
  endNode,
  error,
  helperText,
  label,
  multiple,
  placeholder,
  readOnly,
  required,
  selectItems,
  startNode,
  testId,
  type,
  value,
  onActionButtonClick,
  onDeleteItem,
  onSelectDropdownItem,
  onSelectItem,
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const baseClasses = useClasses({
    type,
    multiple,
    value,
    dropDownValue,
    clearAll,
    ...rest
  });
  let mainComponent: ReactNode;
  let errorComponent: ReactNode;
  let endAdornment = endNode && <InputAdornment position="end">{endNode}</InputAdornment>;

  const [open, setOpen] = useState(false);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleActionButtonClick = useCallback(
    (value?: string[] | number[]) => {
      onActionButtonClick && onActionButtonClick(value);
      setOpen(false);
    },
    [onActionButtonClick]
  );

  const handleOnKeyDownNumber: KeyboardEventHandler<HTMLInputElement> = (e: KeyboardEvent<HTMLInputElement>) => {
    if (type === 'number' || type === 'phone') {
      const whiteListKey = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace'].includes(e.key);

      if (!whiteListKey) return e.preventDefault();
    }
  };

  const renderSelectPlaceholder = (multiple: boolean | undefined, placeholder: string | undefined) => {
    if (!Boolean(placeholder) && Boolean(multiple)) return 'Select all that apply';
    return placeholder;
  };

  const renderValue = (selected: unknown) => {
    if (!selectItems) return null;

    if (!multiple && selected !== 'none') {
      const selectedItem = selectItems?.find((v) => v.value === selected);

      return <>{selectedItem?.label}</>;
    }

    if (multiple && (selected as string[]).filter((v) => !['none', 'action'].includes(v)).length > 0) {
      return (
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {(selected as string[]).map((select: string, index: number) => {
            const selectedItem = selectItems?.find((v) => v.value === select);

            if (selectedItem) {
              return (
                <Chip
                  key={index}
                  className={baseClasses.nh_customChipWrapper}
                  label={selectedItem.label}
                  onDelete={() => onDeleteItem && onDeleteItem(index)}
                  onMouseDown={(event) => {
                    event.stopPropagation();
                  }}
                />
              );
            }

            return null;
          })}
        </div>
      );
    }

    return <span className={baseClasses.nh_customPlaceHolder}>{renderSelectPlaceholder(multiple, placeholder)}</span>;
  };

  const setDefaultValue = useMemo(() => {
    if (!value && type === 'select') {
      return multiple ? ['none'] : 'none';
    }

    if (!multiple && type === 'select' && value && `${value}`.length === 0) {
      return 'none';
    }

    if (multiple && type === 'select' && Array.isArray(value) && (value as string[] | number[]).length === 0) {
      return ['none'];
    }

    return value;
  }, [value]);

  const onChangeSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!multiple && rest.onChange) {
      return rest.onChange(event);
    }

    if (multiple && onSelectItem) {
      const value = event.target.value as unknown as string[];

      const newArry = (event.target.value as unknown as string[]).filter((v) => !['none', 'action'].includes(v));
      value.filter((item) => {
        if (item !== 'action') {
          return onSelectItem(newArry);
        } else {
          return handleActionButtonClick(newArry);
        }
      });
    }
  };

  if (clearAll && type !== 'select' && (!rest.disabled || !readOnly)) {
    endAdornment = (
      <InputAdornment position="end">
        <IconButton onClick={() => rest.onReset && rest.onReset()}>
          <ClearIcon />
        </IconButton>
      </InputAdornment>
    );
  }

  if (clearAll && type === 'select' && (!rest.disabled || !readOnly)) {
    endAdornment = (
      <InputAdornment position="end" style={{ position: 'absolute', right: 25 }}>
        <IconButton onClick={() => rest.onReset && rest.onReset()}>
          <ClearIcon fontSize="small" />
        </IconButton>
      </InputAdornment>
    );
  }

  if (type === 'phone') {
    mainComponent = (
      <Div display="flex" flexDirection="row">
        <Div display="flex" width={50} justifyContent="center" alignItems="center">
          <Typography>+65</Typography>
        </Div>
        <MuiTextField
          data-testid={testId}
          autoComplete="off"
          type="text"
          className={baseClasses.nh_customTextField}
          color="primary"
          hiddenLabel
          variant="filled"
          InputProps={{
            className: baseClasses.nh_customTextField,
            onKeyDown: handleOnKeyDownNumber,
            startAdornment: startNode && <InputAdornment position="start">{startNode}</InputAdornment>,
            endAdornment,
            readOnly
          }}
          placeholder={placeholder}
          error={error}
          value={setDefaultValue}
          {...rest}
        />
      </Div>
    );
  }

  if (type === 'password') {
    mainComponent = (
      <MuiTextField
        data-testid={testId}
        type={showPassword ? 'text' : 'password'}
        autoComplete="off"
        className={baseClasses.nh_customTextField}
        color="primary"
        hiddenLabel
        variant="filled"
        placeholder={placeholder}
        InputProps={{
          className: baseClasses.nh_customTextField,
          endAdornment: (
            <InputAdornment position="end">
              <Button disableRipple variant="secondary" withoutBorder onClick={() => setShowPassword((pre) => !pre)}>
                {showPassword ? <VisibilityOff color="action" /> : <Visibility color="action" />}
              </Button>
            </InputAdornment>
          ),
          readOnly
        }}
        error={error}
        value={setDefaultValue}
        {...rest}
      />
    );
  }

  if (type === 'text' || type === 'number') {
    mainComponent = (
      <MuiTextField
        data-testid={testId}
        type="text"
        autoComplete="off"
        className={baseClasses.nh_customTextField}
        color="primary"
        hiddenLabel
        variant="filled"
        InputProps={{
          className: baseClasses.nh_customTextField,
          onKeyDown: handleOnKeyDownNumber,
          startAdornment: startNode && <InputAdornment position="start">{startNode}</InputAdornment>,
          endAdornment,
          readOnly
        }}
        placeholder={placeholder}
        error={error}
        value={setDefaultValue}
        {...rest}
      />
    );
  }

  if (type === 'select') {
    mainComponent = (
      <>
        <MuiTextField
          data-testid={testId}
          type={type}
          autoComplete="off"
          className={baseClasses.nh_customTextField}
          color="primary"
          hiddenLabel
          variant="filled"
          InputProps={{
            className: baseClasses.nh_customTextField,
            startAdornment: startNode && <InputAdornment position="start">{startNode}</InputAdornment>,
            endAdornment,
            readOnly
          }}
          select
          SelectProps={{
            open,
            onClose: handleClose,
            onOpen: handleOpen,
            multiple,
            renderValue,
            readOnly,
            MenuProps: {
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left'
              },
              getContentAnchorEl: null
            },
            IconComponent: ExpandMoreIcon
          }}
          error={error}
          value={setDefaultValue}
          onChange={onChangeSelect}
          {...rest}
        >
          <MenuItem value="none" disabled style={{ display: 'none' }}>
            Placeholder
          </MenuItem>
          {selectItems &&
            selectItems
              .filter((v) => typeof v !== 'undefined' || v !== null)
              .map((option) => (
                <MenuItem key={option.value} value={option.value} className={baseClasses.nh_customMenuItem}>
                  {option.label}
                </MenuItem>
              ))}
          {(onActionButtonClick || actionButtonTitle) && (
            <MenuItem key="action" value="action" className={baseClasses.close_btn}>
              <Button size="small" variant="secondary">
                {actionButtonTitle}
              </Button>
            </MenuItem>
          )}
        </MuiTextField>
      </>
    );
  }
  if (type === 'select-input') {
    mainComponent = (
      <Div display="flex" flexDirection="row">
        <Select
          disabled={rest.disabled}
          className={baseClasses.nh_customSelectInputSelect}
          IconComponent={ExpandMoreIcon}
          readOnly={readOnly}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left'
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left'
            },
            getContentAnchorEl: null
          }}
          error={error}
          value={dropDownValue}
          onChange={(v) => onSelectDropdownItem && onSelectDropdownItem(v.target.value)}
        >
          {selectItems &&
            selectItems
              .filter((v) => typeof v !== 'undefined' || v !== null)
              .map((option) => (
                <MenuItem key={option.value} value={option.value} className={baseClasses.nh_customMenuItem}>
                  {option.label}
                </MenuItem>
              ))}
        </Select>
        <MuiTextField
          data-testid={testId}
          autoComplete="off"
          type="text"
          className={baseClasses.nh_customTextField}
          color="primary"
          hiddenLabel
          variant="filled"
          InputProps={{
            className: baseClasses.nh_customTextField,
            onKeyDown: handleOnKeyDownNumber,
            startAdornment: startNode && <InputAdornment position="start">{startNode}</InputAdornment>,
            endAdornment,
            readOnly
          }}
          placeholder={placeholder}
          error={error}
          value={setDefaultValue}
          {...rest}
        />
      </Div>
    );
  }

  if (error) {
    errorComponent = (
      <Div margin={{ top: helperText ? 10 : 0 }}>
        <Typography color="red" variant="subtitle2">
          {helperText}
        </Typography>
      </Div>
    );
  }

  if (label && label.trim().length > 0) {
    return (
      <Div>
        <Div margin={{ bottom: 10 }}>
          <Typography bold>
            {label} {required ? <span className={baseClasses.nh_customRequiredField}>*</span> : ''}
          </Typography>
        </Div>
        {mainComponent}
        {errorComponent}
      </Div>
    );
  }

  return (
    <>
      {mainComponent} {errorComponent}
    </>
  );
};

export default TextField;
