import type { ThemeOptions } from '@material-ui/core';

import { createTheme } from '@material-ui/core/styles';

// MAIN TYPOGRAPHY
import typography from './typography';

// MAIN COLOR
import ntucHealth from './ntucHealth';

// MAIN PALETTE
import palette from './palette';

const baseTheme: ThemeOptions = {
  palette,
  ntucHealth,
  typography,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 970,
      lg: 1280,
      xl: 1920
    }
  }
};

const ntucHealthTheme = createTheme(baseTheme);

export default ntucHealthTheme;
