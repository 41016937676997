import { ThemeOptions } from '@material-ui/core';

const ntucHealth: ThemeOptions['ntucHealth'] = {
  teal700: 'rgb(0, 156, 166)',
  green600: 'rgb(102, 179, 51)',
  charcoal900: 'rgb(43, 43, 43)',
  charcoal800: 'rgb(94, 94, 95)',
  charcoal700: 'rgb(92, 92, 92)',
  charcoal500: 'rgb(187, 187, 187)',
  charcoal300: 'rgb(226, 226, 226)',
  charcoal100: 'rgb(247, 247, 247)',
  greyBlack: 'rgb(30,30,30)',
  white: 'rgb(255, 255, 255)',
  ice50: 'rgb(232, 240, 240)',
  red900: 'rgb(166, 26, 8)',
  lilac400: 'rgb(149, 81, 148)',
  limeGreen: 'rgb(246, 251, 254)',
  pine700: 'rgb(0, 119, 84)',
  sienna900: 'rgb(119, 103, 50)',
  grey200: 'rgb(238, 238, 238)',
  yellowOrange: 'rgb(255, 182, 15)',
  yellowWhite: 'rgb(255, 253, 234)',
  calendar: {
    acknowledged: 'rgba(4, 100, 156, 1)',
    cancelled: 'rgba(224, 38, 86, 1)',
    cancelledWithCharge: 'rgba(161, 112, 79, 1)',
    completed: 'rgba(101, 180, 52, 1)',
    leaves: 'rgba(224, 38, 86, 1)',
    lightLabel: 'rgba(0, 0, 0, 0.38)',
    listRowBorder: 'rgba(0, 0, 0, 0.5)',
    ongoing: 'rgba(4, 100, 156, 1)',
    publicHolidays: 'rgba(255, 204, 203, 1)',
    rowHeader: 'rgba(4, 100, 156, 1)',
    scheduled: 'rgba(4, 100, 156, 1)',
    softBooked: 'rgba(101, 180, 52, 1)',
    workingHours: 'rgba(4, 4, 4, 1)',
    greyBackground: 'rgba(248, 248, 248, 1)',
    lightGreyBorder: 'rgba(235, 235, 235, 1)'
  }
};

export default ntucHealth;
