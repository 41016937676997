import React, { FC } from 'react';
import MuiAvatar from '@material-ui/core/Avatar';
import clsx from 'clsx';

// INTERFACC
import { AvatarProps } from './Avatar.interface';

// STYLE
import useClasses from './Avatar.style';

// DEFAULT
import { UserDefaultImage } from 'data';

const Avatar: FC<AvatarProps> = ({ url, size, children, className: classNameProps, backgroundColor, testId }) => {
  const baseClass = useClasses({ size, url, backgroundColor });
  let className = '';
  const defaultImage = url && url.length > 0 ? url : UserDefaultImage;

  if (size === 'xs') {
    className = baseClass.xs;
  }

  if (size === 'sm') {
    className = baseClass.sm;
  }

  if (size === 'md') {
    className = baseClass.md;
  }

  if (size === 'lg') {
    className = baseClass.lg;
  }

  if (size === 'xl') {
    className = baseClass.xl;
  }

  className = clsx(className, classNameProps);

  if (children) {
    return (
      <MuiAvatar data-testid={testId} className={className}>
        {children}
      </MuiAvatar>
    );
  }

  return <MuiAvatar data-testid={testId} alt={`avartar-${url}`} src={defaultImage} className={className} />;
};
Avatar.defaultProps = {
  size: 'sm'
};

export default Avatar;
