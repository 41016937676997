import React, { FC } from 'react';
import { Container as MuiContainer } from '@material-ui/core';

// INTERFACE
import { ContainerProps } from './Container.interface';

const Container: FC<ContainerProps> = ({ isSmall, children, testId, isProductCatalog, isSmallProductCatalog }) => {
  let maxWidth: any = 'sm';

  if (!isSmall) {
    maxWidth = 'md';
  }

  if (isProductCatalog) {
    maxWidth = 'lg';
  }

  if (isSmallProductCatalog) {
    return (
      <MuiContainer style={{ maxWidth: 1200 }} data-testid={testId}>
        <>{children}</>
      </MuiContainer>
    );
  }

  return (
    <MuiContainer data-testid={testId} maxWidth={maxWidth}>
      <>{children}</>
    </MuiContainer>
  );
};

export default Container;
