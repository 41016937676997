import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import clsx from 'clsx';
import { DivProps } from './Div.interface';

//STYLE
import useClasses from './Div.styles';

const Div: FC<DivProps> = ({ children, className, shadowLevel, onCLick, testId, ...rest }) => {
  const baseClasses = useClasses({
    shadowLevel,
    onCLick,
    ...rest
  });

  if (rest.gridContainer) {
    return (
      <Box
        data-testid={testId}
        boxShadow={shadowLevel}
        className={clsx(baseClasses.nh_customDivWrapper, className)}
        onClick={onCLick}
      >
        <span className={baseClasses.childrenWrapper}>{children}</span>
      </Box>
    );
  }

  return (
    <Box
      data-testid={testId}
      boxShadow={shadowLevel}
      className={clsx(baseClasses.nh_customDivWrapper, className)}
      onClick={onCLick}
    >
      {children}
    </Box>
  );
};
Div.defaultProps = {
  display: 'block',
  shadowLevel: 0
};

export default Div;
